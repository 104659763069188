import React, { Component, Fragment } from 'react'
import DashboardLayout from '../components/Admin/DashboardLayout'
import appConstants from './../constants/appConstants'
import AuthService from './../shared/services/Auth.service'
import StorageService from './../shared/services/Storage.service'
import UserProfileService from './../shared/services/UserProfile.service'
import { MDBContainer, MDBDataTable, MDBIcon, MDBAlert } from 'mdbreact'
import AdminApi from '../api/admin-api/admin-api'
import LoadingSpinner from './../components/shared/LoadingSpinner.component'
import { toast } from 'react-toastify'
import { phoneToView } from './../shared/formatters/telephone.formatter'

import './BackofficeSearch.scss'

const SearchStateOptions = appConstants.States.map((s) => ({
  value: s.id,
  text: s.name,
}))

const translateActiveStatus = (ind) => {
  switch (parseInt(ind)) {
    case 1:
      return (
        <MDBAlert className="user-active" color="success">
          ACTIVE
        </MDBAlert>
      )
    case 0:
      return (
        <MDBAlert className="user-active" color="warning">
          DEACTIVATED
        </MDBAlert>
      )
    case -1:
      return (
        <MDBAlert className="user-active" color="info">
          PROTECTED
        </MDBAlert>
      )
    case -2:
      return (
        <MDBAlert className="user-active" color="danger">
          BLACKLISTED
        </MDBAlert>
      )
    default:
      return ''
  }
}

const SearchResultsComponent = ({
  isLoading,
  isLoggingIn,
  tableName,
  tableData,
  tableDescription,
  isBlocked,
}) => {
  const columns = Array.isArray(tableData?.columns) ? tableData.columns : [],
    rows = Array.isArray(tableData?.rows) ? tableData.rows : [],
    noRows = {
      columns: [{ label: 'Name', field: 'name', sort: 'asc' }],
      rows: isBlocked
        ? [
            {
              name: (
                <MDBAlert color="danger" className="text--center">
                  <h3 className="fw--500">
                    <MDBIcon icon="exclamation-triangle" />
                    &nbsp;You do not have access to agent search.
                  </h3>
                </MDBAlert>
              ),
            },
          ]
        : [
            {
              name: (
                <MDBAlert color="warning" className="no-rows">
                  <MDBIcon icon="exclamation-triangle" />
                  &nbsp;There are no results by the selected search term. Please
                  try again.
                </MDBAlert>
              ),
            },
          ],
    }

  return (
    <div className="SearchResultsComponent">
      <div className="results-description">
        <h5>Search Results: {tableName}</h5>
        {tableDescription ? <p>{tableDescription}</p> : <></>}
      </div>
      <div className="results-table">
        {rows.length ? (
          <MDBDataTable
            responsive
            striped
            bordered
            small
            data={{ columns, rows }}
            className="text-capitalize usabg-datatable"
          />
        ) : (
          <MDBDataTable
            responsive
            striped
            bordered
            small
            data={noRows}
            className="text-capitalize usabg-datatable"
          />
        )}
      </div>
    </div>
  )
}

const USER_SEARCH_COLUMNS = [
  {
    label: 'Name',
    field: 'name',
    sort: 'asc',
  },
  {
    label: 'Usertype',
    field: 'usertype',
    sort: 'asc',
  },
  {
    label: 'Commission Level',
    field: 'comm_level',
    sort: 'asc',
  },
  {
    label: 'Upline',
    field: 'upline',
    sort: 'asc',
  },
  {
    label: 'Email',
    field: 'email',
    sort: 'asc',
  },
  {
    label: 'Phone',
    field: 'phone',
    sort: 'asc',
  },
  {
    label: 'Active Account?',
    field: 'active',
    sort: 'asc',
  },
  {
    label: 'Login',
    field: 'login',
    sort: 'asc',
  },
]

const STATE_LICENSE_SEARCH_COLUMNS = [
  {
    label: 'Name',
    field: 'name',
    sort: 'asc',
  },
  {
    label: 'Usertype',
    field: 'usertype',
    sort: 'asc',
  },
  {
    label: 'Commission Level',
    field: 'comm_level',
    sort: 'asc',
  },
  {
    label: 'Upline',
    field: 'upline',
    sort: 'asc',
  },
  {
    label: 'Email',
    field: 'email',
    sort: 'asc',
  },
  {
    label: 'Phone',
    field: 'phone',
    sort: 'asc',
  },
  {
    label: 'Subsite',
    field: 'subsite',
    sort: 'asc',
  },
  {
    label: 'View Profile',
    field: 'login',
    sort: 'asc',
  },
]

const SPOKEN_LANGAUGE_SEARCH_COLUMNS = [
  {
    label: 'Name',
    field: 'name',
    sort: 'asc',
  },
  {
    label: 'Usertype',
    field: 'usertype',
    sort: 'asc',
  },
  {
    label: 'Commission Level',
    field: 'comm_level',
    sort: 'asc',
  },
  {
    label: 'Upline',
    field: 'upline',
    sort: 'asc',
  },
  {
    label: 'Email',
    field: 'email',
    sort: 'asc',
  },
  {
    label: 'Phone',
    field: 'phone',
    sort: 'asc',
  },
  {
    label: 'Spoken Language',
    field: 'spoken_language',
    sort: 'asc',
  },
  {
    label: 'View Profile',
    field: 'login',
    sort: 'asc',
  },
]

class SearchResultsFormatter {
  _searchScope = null
  _results = []

  constructor(searchScope) {
    this._searchScope = searchScope
  }

  setResults = (results) =>
    (this._results = results && Array.isArray(results) ? results : [])

  getColumns = (tableName, actionComponent) => {
    tableName = tableName ? tableName : 'all'

    let columns = []
    if (this.isUserSearch()) columns = [...USER_SEARCH_COLUMNS]
    else if (this.isStateLicenseSearch())
      columns = [...STATE_LICENSE_SEARCH_COLUMNS]
    else if (this.isSpokenLanguageSearch())
      columns = [...SPOKEN_LANGAUGE_SEARCH_COLUMNS]

    if (actionComponent === false)
      columns = columns.filter((col) => col.field !== 'login')

    if (
      !UserProfileService.isA([
        'internal-admin',
        'internal-staff',
        'system-admin',
      ])
    )
      columns = columns.filter((col) => col.field !== 'comm_level')

    if (tableName === 'downline')
      return columns.filter(
        (col) => col.field !== 'login' || !StorageService.get('child_token')
      )
    if (tableName === 'agency')
      return columns.filter(
        (col) => col.field !== 'login' || !StorageService.get('child_token')
      )
    if (tableName === 'all') return columns
    return []
  }

  getTableData = (tableName, actionComponent, userId) => {
    tableName = tableName ? tableName : 'all'
    return {
      columns: this.getColumns(tableName, actionComponent),
      rows: this._formatRows(
        this._filter(tableName, null, userId),
        actionComponent
      ),
    }
  }

  _formatRows = (results, actionComponent) => {
    results = results ? results : this._results

    return results.map((row) => {
      let r = {
        name: (
          <>
            {row.u_fullname}
            {parseInt(row?.u_completed_enroll) !== 1 ? (
              <>
                <small style={{ display: 'block' }} className="text--red">
                  <strong>Agent has not completed enrollment.</strong>
                </small>
                <small style={{ display: 'block' }} className="text--red">
                  <strong>Login to complete.</strong>
                </small>
              </>
            ) : (
              ''
            )}
          </>
        ),
        usertype: row.usertypename,
        comm_level: row?.comm_level,
        upline: row.upline_fullname,
        email: row.u_email,
        phone: row.u_phone,
        spoken_language:
          row.u_spoken_language && typeof row.u_spoken_language === 'string' ? (
            JSON.parse(row.u_spoken_language).join(', ')
          ) : (
            <></>
          ),
        subsite: row?.u_login ? (
          <a
            href={`https://usabg.com/${row?.u_login}`}
            target="_BLANK"
            rel="noopener noreferrer"
          >
            Open Subsite
          </a>
        ) : (
          <></>
        ),
        active: translateActiveStatus(row.u_active),
      }

      r.login = ''
      if (actionComponent) r.login = actionComponent(row.id)
      else delete r.login

      return r
    })
  }

  _filter = (tableName, results, userId) => {
    results = results ? results : this._results
    if (tableName === 'downline')
      return results.filter(
        (row) => row?.signature && row.signature.indexOf(`${userId}`) > -1
      )
    if (tableName === 'agency')
      return results.filter(
        (row) => row?.signature && row.signature.indexOf(`${userId}`) < 0
      )
    if (tableName === 'all') return results
    return []
  }

  isUserSearch = () => this._searchScope === 'users'

  isStateLicenseSearch = () => this._searchScope === 'states'
  isSpokenLanguageSearch = () => this._searchScope === 'languages'
}

class BackofficeSearch extends Component {
  state = {
    isLoading: true,
    isLoggingIn: false,
    urlParams: {},
    agencyResults: { columns: [], rows: [] },
    downlineResults: { columns: [], rows: [] },
    allResults: { columns: [], rows: [] },
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    this.setState({ urlParams: params }, this._performSearch)

    if (!UserProfileService.isAssumed() && StorageService.get('child_token'))
      StorageService.clear('child_token')
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.match.params.str !== prevState.urlParams.str
      ? { urlParams: nextProps.match.params }
      : null // <- this is setState equivalent
  }

  componentDidUpdate(prevProps, prevState) {
    let hasStateChanged = prevState.urlParams.str !== this.state.urlParams.str
    if (!hasStateChanged)
      hasStateChanged =
        prevState.urlParams.searchScope !== this.state.urlParams.searchScope

    if (hasStateChanged) {
      const {
        match: { params },
      } = this.props

      this.setState({ urlParams: params, isLoading: true }, this._performSearch)
    }
  }

  _isStateLicenseSearch = () =>
    this.state.urlParams && this.state.urlParams?.searchScope === 'states'

  _isUserSearch = () =>
    this.state.urlParams && this.state.urlParams?.searchScope === 'users'

  _isSpokenLanguageSearch = () =>
    this.state.urlParams && this.state.urlParams?.searchScope === 'languages'

  _handleSuccess = (results) => {
    const isAdmin = UserProfileService.isA(
        ['system-admin', 'internal-admin', 'internal-staff', 'recruiter-group'],
        true
      ),
      Formatter = new SearchResultsFormatter(this.state.urlParams?.searchScope),
      userId = UserProfileService.isA(
        ['division-admin', 'region-admin', 'district-admin'],
        true
      )
        ? UserProfileService.get('u_upline_id', true)
        : UserProfileService.getUserId()

    Formatter.setResults(results)

    const stateUpd = {
      isLoading: false,
      agencyResults: Formatter.getTableData(
        'agency',
        this._renderViewUserBtn,
        userId
      ),
      downlineResults: Formatter.getTableData(
        'downline',
        this._renderAssumeUserBtn,
        userId
      ),
      allResults: Formatter.getTableData(
        'all',
        isAdmin
          ? this._isUserSearch()
            ? this._renderAssumeUserBtn
            : this._renderViewUserBtn
          : false,
        userId
      ),
    }
    this.setState((prevState) => ({
      ...prevState,
      ...stateUpd,
      isLoading: false,
    }))
  }

  _handleError = (response) => {
    console.log('ERROR : ', response)
  }

  _performSearch = async () => {
    if (!this.state.isLoading) this.setState({ isLoading: true })

    let results = []
    try {
      if (this._isStateLicenseSearch())
        results = await this._fetch(this.state.urlParams?.str)
      if (this._isUserSearch())
        results = await this._fetch(this.state.urlParams?.str)
      if (this._isSpokenLanguageSearch())
        results = await this._fetch(this.state.urlParams?.str)
    } catch (ex) {
      console.error(ex)
      results = false
    }
    if (Array.isArray(results)) this._handleSuccess(results)

    this.setState({ isLoading: false })
  }

  _fetch = async () => {
    let response,
      method = ''

    if (this._isUserSearch()) method = 'getUsersFromSearchTerm'
    else if (this._isStateLicenseSearch()) method = 'getUsersFromStateLicense'
    else if (this._isSpokenLanguageSearch())
      method = 'getUsersFromSpokenLanguage'

    try {
      response = method ? await AdminApi[method](this.state.urlParams.str) : {}
    } catch (ex) {
      console.log(`Failed to search state licenses ${ex}`)
    }

    if (parseInt(response?.status) >= 200 && parseInt(response.status) < 300)
      return (response?.data?.data || []).map((user) => ({
        ...user,
        u_phone: user.u_phone && phoneToView(user.u_phone),
      }))

    this._handleError(response)
  }

  _assumeUser = (userId) => {
    if (this.state.isLoggingIn) return
    this.setState({ isLoggingIn: true })

    AuthService.assumeUser(userId)
      .then(
        () => this.props.history.push('/account-profile'),
        (err) => toast.error(err, { position: toast.POSITION.TOP_RIGHT })
      )
      .finally(() => this.setState({ isLoggingIn: false }))
      .catch((err) => toast.error(err, { position: toast.POSITION.TOP_RIGHT }))
  }

  _renderAssumeUserBtn = (userId) => {
    return (
      <div className={'d-flex align-items-center justify-content-center'}>
        <div
          className={'cursor-pointer d-inline-block'}
          role={'button'}
          onClick={() => this._assumeUser(userId)}
        >
          <MDBIcon
            icon="sign-in-alt"
            size="2x"
            className="d-flex justify-content-center blue-text p-1"
          />
        </div>
      </div>
    )
  }

  _viewUser = (userId) => {
    this.props.history.push(`/${userId}/account-profile`)
  }

  _renderViewUserBtn = (userId) => {
    return (
      <div className={'d-flex align-items-center justify-content-center'}>
        <div
          className={'cursor-pointer d-inline-block'}
          role={'button'}
          onClick={() => this._viewUser(userId)}
        >
          <MDBIcon
            icon="sign-in-alt"
            size="2x"
            className="d-flex justify-content-center blue-text p-1"
          />
        </div>
      </div>
    )
  }

  renderTables = () => {
    if (this._isUserSearch()) {
      if (
        UserProfileService.isA(
          ['agency-owner', 'internal-staff', 'internal-admin', 'system-admin'],
          true
        )
      )
        return (
          <SearchResultsComponent
            isLoading={this.state.isLoading}
            isLoggingIn={this.state.isLoggingIn}
            tableData={this.state.allResults}
            tableName={'All Users'}
            tableDescription={
              'These agents are in any USABG team.  You can access all agent features.'
            }
          />
        )

      if (UserProfileService.isA(['recruiter-group'], true))
        return (
          <>
            <SearchResultsComponent
              isLoading={this.state.isLoading}
              isLoggingIn={this.state.isLoggingIn}
              tableData={this.state.downlineResults}
              tableName={'My Downline Agents'}
              tableDescription={
                'These agents are in your downline.  You can access all downline agent features.'
              }
            />
            <SearchResultsComponent
              isLoading={this.state.isLoading}
              isLoggingIn={this.state.isLoggingIn}
              tableData={this.state.agencyResults}
              tableName={'USABG Agency Agents'}
              tableDescription={
                'These agents are NOT in your downline.  You will only be able to view agent profile.'
              }
            />
          </>
        )

      if (
        UserProfileService.isA(
          ['division-admin', 'region-admin', 'district-admin'],
          true
        )
      )
        return (
          <>
            <SearchResultsComponent
              isLoading={this.state.isLoading}
              isLoggingIn={this.state.isLoggingIn}
              tableData={this.state.downlineResults}
              tableName={'Division Downline Agents'}
              tableDescription={
                'These agents are in the division downline.  You can access all division downline agent features.'
              }
            />
            <SearchResultsComponent
              isLoading={this.state.isLoading}
              isLoggingIn={this.state.isLoggingIn}
              tableData={this.state.agencyResults}
              tableName={'USABG Agency Agents'}
              tableDescription={
                'These agents are NOT in your downline.  You will only be able to view agent profile.'
              }
            />
          </>
        )

      return (
        <SearchResultsComponent
          isLoading={this.state.isLoading}
          isLoggingIn={this.state.isLoggingIn}
          tableData={[]}
          tableName={'All Users'}
          isBlocked={true}
        />
      )
    }

    if (this._isStateLicenseSearch()) {
      let State = SearchStateOptions.filter(
        (ST) =>
          this.state.urlParams.str &&
          ST.value === `${this.state.urlParams.str}`.trim().toUpperCase()
      ).shift()?.text
      return (
        <SearchResultsComponent
          isLoading={this.state.isLoading}
          isLoggingIn={this.state.isLoggingIn}
          tableData={this.state.allResults}
          tableName={`${this.state.urlParams.str} Agent State Licenses`}
          tableDescription={`These Signature Agents are licensed in the State of ${State}.`}
        />
      )
    }

    if (this._isSpokenLanguageSearch()) {
      return (
        <SearchResultsComponent
          isLoading={this.state.isLoading}
          isLoggingIn={this.state.isLoggingIn}
          tableData={this.state.allResults}
          tableName={`Agents who can speak in ${this.state.urlParams.str}`}
          tableDescription={`Spoken language search results will only include active agents.`}
        />
      )
    }
  }

  render() {
    toast.configure()

    return (
      <Fragment key={this.state.urlParams.str}>
        <DashboardLayout>
          <main id="BackofficeSearchPage" className="mainSection">
            <MDBContainer fluid className="mt-5">
              <div
                className={
                  'content-wrapper ' +
                  (this.state.isLoading || this.state.isLoggingIn
                    ? 'is-loading '
                    : ' ')
                }
              >
                <div className="search-loading-spinner">
                  <LoadingSpinner
                    size="md"
                    name="pages.search.results"
                    isActive={this.state.isLoading || this.state.isLoggingIn}
                    label={
                      this.state.isLoggingIn
                        ? 'Logging In As Agent ...'
                        : 'Searching Agents ...'
                    }
                  />
                </div>
                <div className="search-results">{this.renderTables()}</div>
              </div>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default BackofficeSearch
