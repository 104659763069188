import React from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import ToggleAutoAwards from './../ToggleAutoAwards/ToggleAutoAwards.component'
import ToggleSpending from './../ToggleSpending/ToggleSpending.component'
import ToggleWalletLock from './../ToggleWalletLock/ToggleWalletLock.component'
import LedgerTransactionRuleService from './../../../shared/services/LedgerTransactionRule.service'
import UserProfileService from './../../../shared/services/UserProfile.service'

import './AgentLedgerControls.component.scss'

class AgentLedgerControlsStore {
  ledgerId = null
  isLoading = null
  isWalletLocked = null
  hasLoaded = false
  preventAutoAwards = null

  constructor() {
    makeAutoObservable(this)
  }

  hasInit = (ledgerId) => {
    return (ledgerId && `${ledgerId}` === `${this.hasLoaded}`) || this.isLoading
  }

  init = async (ledgerId) => {
    this.isLoading = true
    if ((this.ledgerId = parseInt(ledgerId))) {
      const [preventAutoAwards, isWalletLocked, preventSpending] =
        await Promise.all([
          this.getAutoAwards(),
          this.getWalletLocked(),
          this.getSpending(),
        ])
      this.preventAutoAwards = preventAutoAwards
      this.isWalletLocked = isWalletLocked
      this.preventSpending = preventSpending
    }
    this.hasLoaded = parseInt(ledgerId)
    this.isLoading = false
  }

  getAutoAwards = async () => {
    return await LedgerTransactionRuleService.getAutoAwards(this.ledgerId)
  }

  toggleAutoAwards = async () => {
    if (this.isLoading) return

    this.isLoading = true

    if (this.ledgerId) {
      if (this.preventAutoAwards) {
        await LedgerTransactionRuleService.enableAutoAwards(this.ledgerId)
      } else {
        await LedgerTransactionRuleService.removeAutoAwards(this.ledgerId)
      }

      this.preventAutoAwards = await this.getAutoAwards()
    }

    this.isLoading = false
  }

  getWalletLocked = async () => {
    return await LedgerTransactionRuleService.getWalletLocked(this.ledgerId)
  }

  toggleWalletLock = async () => {
    if (this.isLoading) return

    this.isLoading = true

    if (this.ledgerId) {
      if (this.isWalletLocked) {
        await LedgerTransactionRuleService.unlockWallet(this.ledgerId)
      } else {
        await LedgerTransactionRuleService.lockWallet(this.ledgerId)
      }

      this.isWalletLocked = await this.getWalletLocked()
    }

    this.isLoading = false
  }

  getSpending = async () => {
    return await LedgerTransactionRuleService.getSpending(this.ledgerId)
  }

  toggleSpending = async () => {
    if (this.isLoading) return

    this.isLoading = true

    if (this.ledgerId) {
      if (this.preventSpending) {
        await LedgerTransactionRuleService.enableSpending(this.ledgerId)
      } else {
        await LedgerTransactionRuleService.removeSpending(this.ledgerId)
      }

      this.preventSpending = await this.getSpending()
    }

    this.isLoading = false
  }
}

const ControlStore = new AgentLedgerControlsStore()

const AgentLedgerControls = ({ ledgerId }) => {
  const isAdmin = UserProfileService.isA(
    [
      'divisional-leader-admin',
      'system-admin',
      'internal-admin',
      'internal-staff',
    ],
    true
  )

  if (ledgerId && !ControlStore.hasInit(ledgerId)) ControlStore.init(ledgerId)

  return (
    <div id="AgentLedgerControlsComponent" className="container-fluid">
      {isAdmin ? (
        <ul className="ledger-settings">
          <li>
            <ToggleWalletLock ControlStore={ControlStore} />
          </li>
          <li>
            <ToggleAutoAwards ControlStore={ControlStore} />
          </li>
          <li>
            <ToggleSpending ControlStore={ControlStore} />
          </li>
        </ul>
      ) : (
        <></>
      )}
    </div>
  )
}

export default observer(AgentLedgerControls)
