import BaseModelInstance from './base.model'
import { User as ModelDefinition } from './model-definitions'
import {
  UserContractCartFactory,
  UsersCarrierContractFactory,
  UserMetaFactory,
  LedgerFactory,
  UserCertificationFactory,
  UserExternalCarrierFactory,
  UserEventLogFactory,
} from './../factories'
import UsertypeService from './../services/Usertype.service'
import UserService from './../services/User.service'
import StripeCustomerService from './../services/StripeCustomer.service'
import StripePaymentService from './../services/StripePayment.service'

class StripeUserService {
  // User instance that this service is working on behalf of.
  _User = null

  // key: value pair of options.
  _opts = { sub_class: undefined }

  _customer = null

  async customer(customerId) {
    if (this._customer && customerId && this._customer.id === customerId)
      return this._customer

    if (customerId) {
      try {
        return (this._customer = await StripeCustomerService.get(customerId, {
          expand: ['customer', 'paymentMethod'],
        }))
      } catch (ex) {
        return Promise.reject(ex)
      }
    }

    if (this._customer) return this._customer

    return Promise.reject()
  }

  async customers() {
    try {
      let cust = await StripeCustomerService.search({
        search: { user_id: this._User.id() },
      })
      return Array.isArray(cust?.models) ? cust.models : []
    } catch (ex) {
      return Promise.reject(ex)
    }
  }

  async charge(payload) {
    try {
      return await StripePaymentService.store(payload)
    } catch (ex) {
      return Promise.reject(ex)
    }
  }

  async createPaymentIntents(payload) {
    try {
      return await StripePaymentService.createPaymentIntents(payload)
    } catch (ex) {
      return Promise.reject(ex)
    }
  }

  async confirmPayment(payload) {
    try {
      return await StripePaymentService.confirmPayment(payload)
    } catch (ex) {
      return Promise.reject(ex)
    }
  }

  constructor(User, opts) {
    this._User = User
    this._opts = opts && typeof opts === 'object' ? opts : {}
  }
}

class User extends BaseModelInstance {
  afterSet = (key, value) => {}

  name = () =>
    [this.get('u_fname'), this.get('u_lname')]
      .filter((n) => n)
      .join(' ')
      .trim()

  isA = (usertype) => {
    usertype = Array.isArray(usertype) ? usertype : [usertype]
    for (let u in usertype)
      if (UsertypeService.isA(usertype[u], this.get('usertype_id'))) return true
    return false
  }

  hasCompletedTrainings = async () => {
    try {
      return await UserService.hasCompletedTrainings(this.id())
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  getRequiredTrainings = async () => {
    try {
      return await UserService.hasCompletedTrainings(this.id(), {
        expand: true,
      })
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  hasCompletedTerms = async () => {
    try {
      return await UserService.hasCompletedTerms(this.id())
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  getRequiredTerms = async () => {
    try {
      return await UserService.hasCompletedTerms(this.id(), { expand: true })
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  hasCompletedGoals = async () => {
    try {
      return await UserService.hasCompletedGoals(this.id())
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  getRequiredGoals = async () => {
    try {
      return await UserService.hasCompletedGoals(this.id(), { expand: true })
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  signTerms = async (request) => {
    request = request && typeof request === 'object' ? request : {}
    // request.term_slugs 	= Array<string>
    // request.term_ids 		= Array<number>
    // request.accepted_ip 	= string
    //

    try {
      return await UserService.signTerms(this.id(), request)
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  getDownline = async () => {
    try {
      return await UserService.getDownline(this.id())
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  getDownlineLeaders = async () => {
    try {
      // new BaseModelInstance(ModelDefinition, )
      return await UserService.getDownlineLeaders(this.id())
    } catch (ex) {
      console.error(`${ex}`)
      return false
    }
  }

  externalCarrier = () => ({
    all: async () =>
      (await UserExternalCarrierFactory.search({
        search: { user_id: this.get('id') },
        pagination: false,
      })) ||
      {} ||
      [],
    create: async (data) =>
      await UserExternalCarrierFactory.create({
        ...(data ? data : {}),
        user_id: this.get('id'),
      }),
  })

  cart = () => ({
    all: async () =>
      (await UserContractCartFactory.search({
        search: { user_id: this.get('id') },
        pagination: false,
      })) ||
      {} ||
      [],
    create: async (data) =>
      await UserContractCartFactory.create({
        ...(data ? data : {}),
        user_id: this.get('id'),
      }),
  })

  contract = () => ({
    all: async () =>
      (await UsersCarrierContractFactory.search({
        search: { user_id: this.get('id') },
        pagination: false,
      })) ||
      {} ||
      [],
    create: async (data) =>
      await UsersCarrierContractFactory.create({
        ...(data ? data : {}),
        user_id: this.get('id'),
      }),
  })

  meta = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (await UserMetaFactory.search({
            search: { user_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        key: async (metaKey) =>
          (await UserMetaFactory.search({
            search: { meta_key: metaKey, user_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        create: async (data) =>
          await UserMetaFactory.create({
            ...(data ? data : {}),
            user_id: this.id(),
          }),
      }
    }

    return this.children('meta') || []
  }

  history = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (await UserEventLogFactory.search({
            search: { user_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        search: async ({ search, pagination, orderBy }) =>
          await UserEventLogFactory.search({
            search: {
              ...(search && typeof search === 'object' ? search : {}),
              user_id: this.id(),
            },
            pagination: pagination
              ? pagination
              : pagination === false
              ? false
              : pagination,
            order_by: orderBy || {},
          }),
        create: async (data) =>
          await UserEventLogFactory.create({
            ...(data ? data : {}),
            user_id: this.id(),
          }),
      }
    }

    return this.children('history') || []
    // return Array.isArray(this.#children?.history) ? this.#children.history : []
  }

  ledger = (useApi) => {
    if (useApi === true) {
      return {
        all: async () =>
          (await LedgerFactory.search({
            search: { user_id: this.id() },
            pagination: false,
          })) ||
          {} ||
          [],
        create: async (data) =>
          await LedgerFactory.create({
            ...(data ? data : {}),
            user_id: this.id(),
          }),
      }
    }

    return this.children('history') || []
    // return Array.isArray(this.#children?.meta) ? this.#children.meta : []
  }

  certification = () => ({
    all: async () =>
      (await UserCertificationFactory.search({
        search: { user_id: this.get('id') },
        pagination: false,
      })) ||
      {} ||
      [],
    create: async (data) =>
      await UserCertificationFactory.create({
        ...(data ? data : {}),
        user_id: this.get('id'),
      }),
  })

  #stripeData = null
  stripe = (opts) =>
    this.#stripeData
      ? this.#stripeData
      : (this.#stripeData = new StripeUserService(this, opts))

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default User
