import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import LeaderReportTable from './components/LeaderReportTable/LeaderReportTable.component'
import moment from 'moment'
import getQueryParam from './../../shared/utilities/getQueryParam.function'
import UserProfileService from './../../shared/services/UserProfile.service'
import LeaderCardStore from './store/LeaderReportCard.store'
import LeaderGoalStore from './store/LeaderGoalCard.store'
import { Subscription, BehaviorSubject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import './LeaderReportCard.page.scss'
import ContentHtml from '../../components/content/ContentHtml/ContentHtml.component'

const DEFAULT_VIEW_MODE = 'reports',
  LEADER_REPORTS = ['division', 'region', 'district', 'career', 'recruiter'],
  FormattedReports = {},
  StoredReports = {}

function updateQueryString(queryObj) {
  if (queryObj && typeof queryObj === 'object' && window.history.pushState) {
    var newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      Object.keys(queryObj)
        .map((key) => queryObj[key] && `${key}=${queryObj[key]}`)
        .filter((n) => !!n)
        .join('&')
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

function getDateNotInFuture(reportDate) {
  reportDate = reportDate ? reportDate : moment()

  if (reportDate && typeof reportDate === 'string')
    reportDate = moment(reportDate, 'YYYY-MM-DD')

  if (reportDate && typeof reportDate === 'object') {
    reportDate = moment.isMoment(reportDate) ? reportDate : moment(reportDate)
    reportDate = reportDate.isAfter(moment()) ? moment() : reportDate
  }

  return reportDate
}

class LeaderReportCardPage extends React.Component {
  _subscriptions$ = null
  _reportParams$ = null

  getQueryParams = () => {
    const reportDate = /^\d{4}-\d{2}-\d{2}$/.test(
        `${getQueryParam('reportDate', this.props.location.search)}`.trim()
      )
        ? `${getQueryParam('reportDate', this.props.location.search)}`.trim()
        : moment().format('YYYY-MM-DD'),
      sourceType = /^(division|region|district|career|recruiter)$/i.test(
        `${getQueryParam('sourceType', this.props.location.search)}`.trim()
      )
        ? `${getQueryParam('sourceType', this.props.location.search)}`.trim()
        : null,
      viewMode = /^(goals|reports)$/i.test(
        `${getQueryParam('viewMode', this.props.location.search)}`.trim()
      )
        ? `${getQueryParam('viewMode', this.props.location.search)}`.trim()
        : null,
      userId = /^\d+$/i.test(
        `${getQueryParam('userId', this.props.location.search)}`.trim()
      )
        ? `${getQueryParam('userId', this.props.location.search)}`.trim()
        : null

    return {
      reportDate,
      sourceType,
      viewMode,
      userId,
    }
  }

  setReportParams = (params) => {
    const reportDate =
      params?.viewMode === 'report'
        ? getDateNotInFuture(params?.reportDate || moment()).format(
            'YYYY-MM-DD'
          )
        : params?.reportDate || moment().format('YYYY-MM-DD')
    updateQueryString(
      (params = { ...this._reportParams$.getValue(), ...params, reportDate })
    )

    if (this._reportParams$) this._reportParams$.next(params)
  }

  onParamsChange = (params) => {
    const userId =
        params?.userId && !isNaN(params.userId)
          ? `${params.userId}`
          : this.getQueryParams()?.userId || UserProfileService.getUserId(),
      viewMode = params?.viewMode || 'reports'

    switch (viewMode) {
      case 'reports':
        return LeaderCardStore.updParams({
          ...params,
          userId,
          viewMode,
          reportDate: getDateNotInFuture(params?.reportDate || moment()).format(
            'YYYY-MM-DD'
          ),
        }).finally(() => this.setState({ viewMode }))
      case 'goals':
        return LeaderGoalStore.updParams({
          ...params,
          userId,
          viewMode,
        }).finally(() => this.setState({ viewMode }))
    }
  }

  init() {
    if (!this._subscriptions$) this._subscriptions$ = new Subscription()

    if (!this._reportParams$) {
      this._reportParams$ = new BehaviorSubject(this.getQueryParams())

      this._subscriptions$.add(
        this._reportParams$
          .pipe(
            distinctUntilChanged((prev, curr) => {
              return !(
                prev.reportDate != curr.reportDate ||
                prev.sourceType != curr.sourceType ||
                prev.viewMode != curr.viewMode ||
                prev.userId != curr.userId
              )
            })
          )
          .subscribe(this.onParamsChange)
      )
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      reportDate:
        this.getQueryParams()?.reportDate || moment().format('YYYY-MM-DD'),
      showPrev: false,
      viewMode: null,
      viewModeOpts: {},
    }
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    if (this._subscriptions$) this._subscriptions$.unsubscribe()

    LeaderCardStore.hasInit = false
    LeaderGoalStore.hasInit = false
  }

  render() {
    return (
      <DashboardLayout>
        <main id="LeaderReportCardPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-4">
            <PageHeading
              label="Leader Report Card"
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
              goTo={(url) =>
                this.props.history.push(url, { from: '/reports/leaders' })
              }
            />
            <MDBRow>
              <MDBCol>
                <ContentHtml contentSlug={'lrc-corporate-goals'} />
              </MDBCol>
              <MDBCol>
                <ContentHtml contentSlug={'lrc-team-goals'} />
              </MDBCol>
              <MDBCol size="12">
                <LeaderReportTable
                  onDateChange={(reportDate) =>
                    this.setReportParams({ reportDate })
                  }
                  viewMode={this.state.viewMode}
                  onViewModeChange={(params) => this.setReportParams(params)}
                  goTo={(goTo) => this.props.history.push(goTo)}
                  ownerName={this.state.viewModeOpts?.ownerName}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default LeaderReportCardPage
