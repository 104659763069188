import React, { Component, Fragment } from 'react'
import DashboardLayout from '../components/Admin/DashboardLayout'
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBFileInput,
} from 'mdbreact'
import { toast } from 'react-toastify'
import { allowedSizeByUploadType } from '../constants/allowedMediaSize'
import env from '../environments/environment'
import { b64toBlob } from '../shared/utilities/b64toBlob.function'
import UploadService from '../shared/services/Upload.service'
import AgencyDocumentService from '../shared/services/AgencyDocument.service'

class VirtualVault extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentsList: '',
      loading: false,
      editing: false,
      error: '',
      modal: false,
      editModal: false,
      doc_id: null,
      doc_title: '',
      doc_description: '',
      title_appended: false,
      description_appended: false,
      doc_url: '',
      upload_doc: null,
      uploading: false,
      edit_doc: null,
      file: null,
      data: {
        columns: [
          {
            label: 'Title',
            field: 'title',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Description',
            field: 'description',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'File Name',
            field: 'filename',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'File Path',
            field: 'filepath',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Update',
            field: 'update',
            sort: 'asc',
            width: 270,
          },
        ],
        rows: [],
      },
    }
  }

  componentDidMount() {
    this.fetchDocumentsData()
  }

  //convert date from sec to current date
  toDateTime = (secs) => {
    let t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  //toggle modal
  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  //Toggle edit modal
  confirm_toggle = (data = {}) => {
    this.setState({ editModal: !this.state.editModal, ...data })
  }

  //forming rows for data table from fetched data
  setRows = () => {
    const { documentsList } = this.state
    if (documentsList && documentsList.length) {
      let rows = documentsList.map((row) => {
        const { id, title, description, filename, filepath } = row
        const self = this

        function editDoc() {
          self.setState({ edit_doc: row })
          self.confirm_toggle()
        }

        const editClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={editDoc}
            >
              <MDBIcon icon="edit" size="2x" className="blue-text p-1" />
            </div>
          </div>
        )

        return {
          title: title,
          description: description,
          filename: filename,
          filepath: filepath,
          update: editClick,
        }
      })
      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  //Fetch data
  fetchDocumentsData = async () => {
    this.setState({ loading: true })
    AgencyDocumentService.search({
      pagination: false,
      order_by: { updated_at: 'DESC' },
    })
      .then((result) => {
        this.setState({ documentsList: result.models || [] })
      })
      .then(() => {
        this.setState({ loading: false })
        this.setRows()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  getContentType = (data) => {
    let match = data && data.split(',')[0].match(/\w+\/\w+/)
    return match && match.shift()
  }

  validateContentType = (uploadContentType, permittedContentTypes) => {
    if (permittedContentTypes.length > 0) {
      if (permittedContentTypes.includes(uploadContentType))
        return !!uploadContentType
      console.log(`Invalid filetype: ${uploadContentType}`)
      return false
    }

    return !!uploadContentType
  }

  validate = (payload) => {
    if (!payload || !payload?.filename || !payload?.data) {
      this.setState({
        validation: 'Missing or invalid file selected. Please try again.',
      })
      return false
    }

    // Define allowed content types for validation
    const allowedContentTypes = [
      'application/pdf', // PDF
      'image/jpeg', // JPG, JPEG
      'image/png', // PNG
      'image/gif', // GIF
      'image/bmp', // BMP
      'audio/mpeg', // MP3
      'application/msword', // DOC
      'application/vnd', // DOCX, XLS, XLSX
      'text/csv', // CSV files
    ]

    if (
      !this.validateContentType(
        this.getContentType(payload.data),
        allowedContentTypes
      )
    ) {
      this.setState({
        validation:
          'Invalid filetype selected. Please select a different file & try again.',
      })
      return false
    }

    return true
  }

  onSelectFile = async (file) => {
    if (file) {
      const reader = new FileReader()

      reader.addEventListener('load', async () => {
        const payload = { filename: file?.name, data: reader.result }

        // Validate file size
        if (file.size > allowedSizeByUploadType['agency_document']) {
          return false
        }

        if (!this.validate(payload)) {
          toast.error(this.state.validation, {
            position: toast.POSITION.TOP_RIGHT,
          })
          return false
        }

        // Build payload to send file to the server.
        const formData = new FormData()
        formData.append('upload_type', 'agency_document')
        if (this.state.doc_title) {
          formData.append('title', this.state.doc_title)
          this.setState({ title_appended: true })
        }
        if (this.state.doc_description) {
          formData.append('description', this.state.doc_description)
          this.setState({ description_appended: true })
        }
        formData.append(
          'base_64',
          b64toBlob(
            payload.data.split(',')[1],
            this.getContentType(payload.data)
          )
        )
        formData.append(`filename`, file.name)
        this.setState({ upload_doc: formData })
      })
      reader.readAsDataURL(file)
    }
  }

  upload = async () => {
    try {
      let formData = this.state.upload_doc
      if (!this.state.title_appended)
        formData.append('title', this.state.doc_title)
      if (!this.state.description_appended)
        formData.append('description', this.state.doc_description)
      this.setState({ upload_doc: formData })

      this.setState({ uploading: true, doc_url: null })
      const result = await UploadService.upload(this.state.upload_doc)
      this.setState({ doc_url: result.file_path, uploading: false })
      toast.success('Document uploaded successfully', {
        position: toast.POSITION.TOP_RIGHT,
      })
      await this.fetchDocumentsData()
    } catch (error) {
      toast.error('Document upload failed', {
        position: toast.POSITION.TOP_RIGHT,
      })
      this.setState({ uploading: false })
    }
  }

  editDoc = async () => {
    try {
      this.setState({ editing: true })
      await AgencyDocumentService.update(this.state.edit_doc.id, {
        title: this.state.edit_doc.title,
        description: this.state.edit_doc.description,
      })
      this.setState({ editModal: false, editing: false })
      toast.success('Document edited successfully', {
        position: toast.POSITION.TOP_RIGHT,
      })
      this.fetchDocumentsData()
    } catch (error) {
      this.setState({ editing: false })
      toast.error('Document edit failed', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  clear = () => {
    this.setState({
      doc_title: '',
      doc_description: '',
      doc_url: '',
      upload_doc: null,
      edit_doc: null,
      file: null,
      title_appended: false,
      description_appended: false,
    })

    const input = document.querySelector('.file-path-wrapper .file-path') // Select the input inside the wrapper
    if (input) {
      input.placeholder = '' // Remove the placeholder
    }
  }

  render() {
    toast.configure()
    const {
      data,
      editModal,
      loading,
      uploading,
      doc_title,
      doc_description,
      doc_url,
      upload_doc,
      edit_doc,
      file,
    } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          <main className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>Virtual Vault</h2>
              <hr />
              <div className="content-wrapper">
                <MDBRow>
                  <MDBCol size="12">
                    <h4>Upload documents</h4>
                    <form className="content-wrapper mt-3">
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="grey-text" htmlFor="doc_title">
                            Title <small>*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="doc_title"
                            id="doc_title"
                            value={doc_title}
                            onChange={(event) =>
                              this.setState({ doc_title: event.target.value })
                            }
                            required
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label
                            className="grey-text"
                            htmlFor="doc_description"
                          >
                            Description <small>*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="doc_description"
                            id="doc_description"
                            value={doc_description}
                            onChange={(event) =>
                              this.setState({
                                doc_description: event.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <MDBFileInput
                            reset
                            value={file}
                            getValue={(value) => this.onSelectFile(value[0])}
                            disabled={
                              !doc_title || !doc_description || uploading
                            }
                          />
                        </div>
                        <div className="form-group col-md-6 d-flex align-items-center justify-content-md-end">
                          {doc_title && doc_description && doc_url && (
                            <MDBBtn
                              color="orange"
                              size={'md'}
                              className="w-50"
                              onClick={this.clear}
                              disabled={
                                !doc_title ||
                                !doc_description ||
                                !upload_doc ||
                                uploading
                              }
                            >
                              Clear
                            </MDBBtn>
                          )}
                          <MDBBtn
                            color="secondary"
                            size={'md'}
                            className="w-50"
                            onClick={this.upload}
                            disabled={
                              !doc_title ||
                              !doc_description ||
                              !upload_doc ||
                              uploading
                            }
                          >
                            {uploading ? `Uploading...` : `Upload`}
                          </MDBBtn>
                        </div>
                        {doc_url ? (
                          <div className="form-group col-md-12">
                            <label className="grey-text" htmlFor="doc_url">
                              Uploaded File URL
                            </label>
                            <div className="position-relative d-flex align-items-center">
                              <input
                                type="text"
                                className="form-control mr-2"
                                name="doc_url"
                                id="doc_url"
                                value={`https://firebasestorage.googleapis.com/v0/b/${
                                  env.integrations.firebase.config.storageBucket
                                }/o/${encodeURIComponent(
                                  decodeURIComponent(doc_url)
                                )}?alt=media`}
                                readOnly
                              />
                              <MDBIcon
                                icon="copy"
                                size="1x"
                                className="blue-text p-1 cursor-pointer"
                                onClick={async (event) => {
                                  await navigator.clipboard.writeText(
                                    `https://firebasestorage.googleapis.com/v0/b/${
                                      env.integrations.firebase.config
                                        .storageBucket
                                    }/o/${encodeURIComponent(
                                      decodeURIComponent(doc_url)
                                    )}?alt=media`
                                  )
                                  toast.success('Uploaded file URL copied', {
                                    position: toast.POSITION.TOP_RIGHT,
                                  })
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </form>
                  </MDBCol>
                  <MDBCol size="12" className="mt-3">
                    {loading ? (
                      <h4 className={'p4'}>Loading...</h4>
                    ) : (
                      <MDBDataTable
                        striped
                        responsive
                        bordered
                        small
                        data={data}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              </div>

              <MDBModal
                size={'md'}
                isOpen={editModal}
                toggle={this.confirm_toggle}
              >
                <MDBModalHeader toggle={this.confirm_toggle}>
                  Edit Document
                </MDBModalHeader>
                <MDBModalBody>
                  <form className="content-wrapper">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label className="grey-text" htmlFor="doc_title">
                          Title <small>*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="doc_title"
                          id="doc_title"
                          value={edit_doc?.title}
                          onChange={(event) =>
                            this.setState({
                              edit_doc: {
                                ...edit_doc,
                                ...{ title: event.target.value },
                              },
                            })
                          }
                          required
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label className="grey-text" htmlFor="doc_description">
                          Description <small>*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="doc_description"
                          id="doc_description"
                          value={edit_doc?.description}
                          onChange={(event) =>
                            this.setState({
                              edit_doc: {
                                ...edit_doc,
                                ...{ description: event.target.value },
                              },
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                  </form>
                  <div className="d-flex justify-content-between align-item-center">
                    <MDBBtn
                      color="secondary"
                      size={'sm'}
                      onClick={this.confirm_toggle}
                      disabled={this.state.editing}
                    >
                      Close
                    </MDBBtn>
                    <MDBBtn
                      color="danger"
                      size={'sm'}
                      onClick={() => {
                        this.editDoc()
                      }}
                      disabled={this.state.editing}
                    >
                      {this.state.editing ? 'Saving...' : 'Save'}
                    </MDBBtn>
                  </div>
                </MDBModalBody>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default VirtualVault
