import React from 'react'
import { makeAutoObservable } from 'mobx'
import Modal from './Modal.component'
import { create } from 'react-modal-promise'
import { loadStripe } from '@stripe/stripe-js'
import environment from '../../../environments/environment'

const ModalPromise = create(Modal)

async function getStripeCustomerId(User) {
  try {
    return (await User.stripe().customers())
      .filter((r) => r.sub_class === 'sig_agent')
      .shift()?.id
  } catch (ex) {
    return Promise.reject(ex)
  }
}

async function getStripeCustomerData(User, customerId) {
  try {
    return await User.stripe().customer(customerId)
  } catch (ex) {
    return Promise.reject(ex)
  }
}

async function loadStripeCustomerData(User) {
  let customerId = (await User.stripe().customers())
    .filter((r) => r.sub_class === 'sig_agent')
    .shift()?.id

  if (!customerId) {
    // no customer exists.
    // go to billing page.
    return false
  }

  const customer = await User.stripe().customer(customerId)

  if (!customer) {
    // customer not found.
    // go to billing page.
    return false
  }

  const paymentMethods = customer?.expands?.paymentMethod
  if (Array.isArray(paymentMethods) && paymentMethods.length > 0)
    return { id: customer.id, paymentMethods }

  return false
}

class LCPMStore {
  User = null
  Ledger = null
  customer = null

  isPurchasing = false
  isLoading = false

  outcome = {
    status: null,
    message: null,
    errors: [],
  }

  purchase = {
    source: null,
    amount: null,
    customer: null,
    user_id: null,
    meta: { ledger_id: null },
  }

  constructor() {
    makeAutoObservable(this)
  }

  paymentMethods(paymentMethodId) {
    if (paymentMethodId)
      return (
        (Array.isArray(this.customer?.expands?.paymentMethod) &&
          this.customer.expands.paymentMethod) ||
        []
      )
        .filter((pm) => pm.id === paymentMethodId)
        .shift()
    return (
      (Array.isArray(this.customer?.expands?.paymentMethod) &&
        this.customer.expands.paymentMethod) ||
      []
    )
  }

  reset(User, Ledger) {
    this.User = User ? User : this.User
    this.Ledger = Ledger ? Ledger : this.Ledger

    this.customer = null

    this.isPurchasing = false
    this.isLoading = false

    this.outcome = {
      status: null,
      message: null,
      errors: [],
    }

    this.purchase = {
      source: null,
      amount: null,
      customer: null,
      user_id: null,
      meta: { ledger_id: null },
    }
  }

  async fetchFromStripe(User, Ledger) {
    this.reset(User, Ledger)

    this.isLoading = true
    const customerId = this.User && (await getStripeCustomerId(this.User))
    this.customer =
      (customerId && (await getStripeCustomerData(this.User, customerId))) ||
      false
    this.isLoading = false
  }

  validatePurchase() {
    let errors = []

    if (isNaN(`${this.purchase.amount}`))
      errors.push(
        'The purchase amount must be a dollar amount greator then zero, without cents.'
      )

    if (!this.purchase.source)
      errors.push('The payment method must be selected.')

    if (
      this.purchase.user_id === null ||
      isNaN(this.purchase.user_id) ||
      this.purchase.meta.ledger_id === null ||
      isNaN(this.purchase.meta.ledger_id) ||
      !this.purchase.customer
    )
      errors.push(
        'The billing details are unavailable right now.  Please try again or complete your purchase later.'
      )

    this.errors = errors.length ? errors : []

    return !this.errors || !this.errors.length
  }

  async purchaseViaStripe(opts) {
    this.isPurchasing = true
    const { sub_class } = opts || {}
    const stripe = await loadStripe(environment.integrations.stripe.key)

    try {
      const { client_secret, payment_method_id, payment_id } =
        await this.User.stripe().createPaymentIntents({
          ...this.purchase,
          amount: parseInt(this.purchase.amount) * 100,
          sub_class: sub_class || 'sig_agent',
        })

      const result = await stripe.confirmCardPayment(client_secret, {
        payment_method: payment_method_id,
      })

      if (result?.error) {
        await this.User.stripe().confirmPayment({
          ...this.purchase,
          amount: parseInt(this.purchase.amount) * 100,
          payment_id: payment_id,
          sub_class: sub_class || 'sig_agent',
          is_success: false,
        })

        this.outcome.message = result.error.message
        this.outcome.status = false
      } else {
        await this.User.stripe().confirmPayment({
          ...this.purchase,
          amount: parseInt(this.purchase.amount) * 100,
          payment_id: payment_id,
          sub_class: sub_class || 'sig_agent',
          payment_intent: {
            id: result.paymentIntent.id,
            created: result.paymentIntent.created,
            status: result.paymentIntent.status,
          },
          is_success: true,
        })
        this.outcome.status = true
      }
    } catch (ex) {
      this.outcome.message = `${ex}`.replace(/error:( )?/gi, '')
      this.outcome.status = false
    }

    this.isPurchasing = false
    return this.outcome.status
  }
}

const Store = new LCPMStore()

class LeadCreditPurchaseModal {
  async open(props) {
    props = props && typeof props === 'object' ? props : {}
    const isOpen = !!(props?.is_open || props?.isOpen)

    Store.fetchFromStripe(props?.user, props?.ledger)

    return new Promise((resolve, reject) => {
      ModalPromise({ ...props, Store, isOpen }).then(resolve, reject)
    })
  }
}

export default new LeadCreditPurchaseModal()
