import { LeaderReportCardPage } from './index'
import {
  GrowthReportPage,
  SubscriptionReportsPage,
  CarrierGrowthReportPage,
} from './index'
import {} from './index'

import { RouteFactory } from './../../shared/router/Route.factory'
import {
  RequiresAuthGuard,
  RequiresUsertypesGuard,
} from './../../shared/router/guards'

export default [
  RouteFactory.create(
    GrowthReportPage,
    '/reports/growth-report',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'divisional-leader',
        'regional-leader',
        'district-leader',
        'internal-admin',
        'system-admin',
        'division-admin',
        'signature-higher-group',
        'signature-group',
      ]),
    ],
    {
      name: 'Personal Growth Report',
    }
  ),
  RouteFactory.create(
    LeaderReportCardPage,
    '/reports/leaders',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'divisional-leader',
        'regional-leader',
        'district-leader',
        'internal-admin',
        'system-admin',
        'division-admin',
        'region-admin',
        'district-admin',
      ]),
    ],
    {
      name: 'Leader Report Cards',
    }
  ),
  RouteFactory.create(
    SubscriptionReportsPage,
    '/reports/subscriptions',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-admin',
        'system-admin',
      ]),
    ],
    {
      name: 'Subscription Reports',
    }
  ),
  RouteFactory.create(
    CarrierGrowthReportPage,
    '/reports/carrier-growth-report',
    [
      new RequiresAuthGuard(true),
      new RequiresUsertypesGuard([
        'agency-owner',
        'internal-staff',
        'internal-admin',
        'system-admin',
      ]),
    ],
    {
      name: 'Carrier Growth Reports',
    }
  ),
]
