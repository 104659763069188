import React from 'react'
import env from './../../../../environments/environment'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import AdminApi from './../../../../api/admin-api/admin-api'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader } from 'mdbreact'

import './ProductListing.component.scss'

const noProductPlaceholder = `https://firebasestorage.googleapis.com/v0/b/${env?.integrations?.firebase?.config?.storageBucket}/o/static%2Fsubsites%2Fimg.no-product.png?alt=media`

class ProductListingStore {
  constructor() {
    makeAutoObservable(this)
  }

  userId = null
  products = []
  isLoading = false

  fetch = async (userId) => {
    if (parseInt(this.userId) !== parseInt(userId)) {
      this.isLoading = true
      this.userId = parseInt(userId)

      try {
        this.products = await AdminApi.showProductLinks({
          user_id: userId,
        }).then((res) => res?.data?.data || [])
      } catch (ex) {
        console.log('CANT LOAD')
      }

      this.isLoading = false
    }
  }
}

const Store = new ProductListingStore()

const ProductListing = ({ subsiteData }) => {
  if (subsiteData && subsiteData?.id && !isNaN(subsiteData?.id))
    Store.fetch(subsiteData?.id)

  const products = React.useMemo(() => {
    return Store.products.filter((item) => {
      if (!item.coverage_products || !item.coverage_products.length)
        return false
      if (
        item.coverage_products.filter((p) => !!p.upc_url || !!p.c_website)
          .length > 0
      )
        return true
      return false
    })
  }, [Store.products])

  return (
    <MDBContainer id="ProductListingComponent" fluid>
      <MDBRow>
        <MDBCol>
          {products && products.length ? (
            products.map((item, i) => {
              return item.coverage_products &&
                item.coverage_products.length > 0 ? (
                <MDBCard className="shell isotope-wrap mt-2" key={i}>
                  <div className="range">
                    <div className="cell-xs-12 text-center p-0">
                      <MDBCardHeader className="product-cat-head">
                        <h4 className="text-spacing--25 font--lato fw--500 text-white">
                          {item.coverage_name ? item.coverage_name : ''}
                        </h4>
                      </MDBCardHeader>
                      <div className="shell">
                        <div className="range">
                          {item.coverage_products &&
                          item.coverage_products.length ? (
                            item.coverage_products
                              .filter(
                                (item, index, self) =>
                                  index ===
                                  self.findIndex(
                                    (obj) => obj.c_website === item.c_website
                                  )
                              )
                              .map((data, i) => {
                                if (!data.upc_url && !data.c_website)
                                  return <React.Fragment key={i} />
                                return (
                                  <div
                                    key={`agent-subsite-product-${i}`}
                                    className="cell-xs-6 cell-md-3"
                                  >
                                    <div className="link-image-wrap">
                                      <a
                                        href={data.upc_url || data.c_website}
                                        target={
                                          data.upc_target
                                            ? data.upc_target
                                            : '_blank'
                                        }
                                        className="link-image"
                                      >
                                        <img
                                          src={
                                            data.c_img
                                              ? `https://firebasestorage.googleapis.com/v0/b/${
                                                  env?.integrations?.firebase
                                                    ?.config?.storageBucket
                                                }/o/${encodeURIComponent(
                                                  !data.c_img.includes(
                                                    'carriers/'
                                                  )
                                                    ? `carriers/${data.c_img}`
                                                    : data.c_img
                                                )}?alt=media`
                                              : noProductPlaceholder
                                          }
                                          alt=""
                                          width="126"
                                          height="68"
                                          className="product-c-img-link"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                )
                              })
                          ) : (
                            <h4 className="text-dark">No Products found</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBCard>
              ) : (
                ''
              )
            })
          ) : (
            <div className="shell isotope-wrap">
              <div className="range">
                <div className="cell-xs-12 text-center">
                  {Store.isLoading ? (
                    <>
                      <i className="fa fa-spin fa-spinner"></i>&nbsp;Loading
                      Products
                    </>
                  ) : (
                    'No Products Available'
                  )}
                </div>
              </div>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(ProductListing)
