import React from 'react'
import { withRouter } from 'react-router-dom'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import {
  MDBContainer,
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSwitch,
} from 'mdbreact'

import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'

import TextEditor from '../../components/shared/TextEditor/TextEditor.component'

import MessageEditor from './../../components/MessageEditor/MessageEditor.component'
class ManageMessagesAlertsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabName: 'messages',
      isLoading: true,
      alerts: [],
      loading: false,
      formError: false,
      errorMessage: '',
      switch1: true,
      alert_type: 2,
      message: '',
      messageBoardAlert: {},
    }
  }

  isActive = (tabName) => this.state.tabName === tabName

  change = (tabName) => {
    if (!this.isActive(tabName)) this.setState({ tabName })
  }

  renderTab = (tabName) => {
    if (tabName === 'alerts')
      return (
        <div
          className={
            'tab-pane fade ' + (this.isActive(tabName) ? 'show active' : '')
          }
          role="tabpanel"
        >
          {this.alertsBoard()}
        </div>
      )
    if (tabName === 'messages')
      return (
        <div
          className={
            'tab-pane fade ' + (this.isActive(tabName) ? 'show active' : '')
          }
          role="tabpanel"
        >
          <MessageEditor hideHeader={true} />
        </div>
      )
    return <></>
  }

  alertsBoard = () => {
    const { submitting, alert_type, switch1, message } = this.state
    return (
      <>
        <MDBRow>
          <MDBCol size="6">
            <MDBAlert color="info">
              <MDBInput
                onClick={this.onRadioClick('info')}
                checked={alert_type === 'info' ? true : false}
                label="Info Alert"
                type="radio"
                id="radio1"
              />
            </MDBAlert>
          </MDBCol>
          <MDBCol size="6">
            <MDBAlert color="danger">
              <MDBInput
                onClick={this.onRadioClick('danger')}
                checked={alert_type === 'danger' ? true : false}
                label="Danger Alert"
                type="radio"
                id="radio2"
              />
            </MDBAlert>
          </MDBCol>
          <MDBCol size="6">
            <MDBAlert color="warning">
              <MDBInput
                onClick={this.onRadioClick('warning')}
                checked={alert_type === 'warning' ? true : false}
                label="Warning Alert"
                type="radio"
                id="radio3"
              />
            </MDBAlert>
          </MDBCol>
          <MDBCol size="6">
            <MDBAlert color="success">
              <MDBInput
                onClick={this.onRadioClick('success')}
                checked={alert_type === 'success' ? true : false}
                label="Success Alert"
                type="radio"
                id="radio4"
              />
            </MDBAlert>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <label>Active</label>
            <MDBSwitch
              checked={switch1}
              onChange={this.handleSwitchChange(1)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <label className="d-block">Content Goes Here</label>
            <TextEditor
              value={this.state.message}
              onChange={(html) => this.setState({ message: html })}
            />

            <MDBBtn color="indigo" onClick={this._handleSubmit}>
              {submitting ? 'Updating...' : 'Update'}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </>
    )
  }

  componentDidMount() {
    this.fetchMessageBoardAlertData()

    const { location } = this.props
    const hash = location.hash
    this.setState({ tabName: hash === '#alerts-md' ? 'alerts' : 'messages' })
  }

  handleSwitchChange = (nr) => () => {
    let switchNumber = `switch${nr}`
    this.setState({
      [switchNumber]: !this.state[switchNumber],
    })
  }

  onRadioClick = (val) => () => {
    this.setState({
      alert_type: val,
    })
  }

  validate = async () => {
    const { alert_type, message } = this.state

    if (!alert_type) {
      this.setState({ formError: true, errorMessage: 'Alert Type Required' })
      return
    }
    if (!message || !message.trim()) {
      this.setState({ formError: true, errorMessage: 'Message Required' })
      return
    }
    this.setState({ formError: false, errorMessage: '' })
  }

  _handleSubmit = async () => {
    await this.validate()
    const {
      submitting,
      formError,
      errorMessage,
      alert_type,
      switch1,
      message,
    } = this.state

    if (submitting) {
      return
    }
    if (formError) {
      toast.error(errorMessage, { position: toast.POSITION.TOP_RIGHT })
      return
    }

    const data = {
      alert_type,
      active: switch1 ? 1 : 0,
      message,
    }

    this.setState({ submitting: true })

    AdminApi.updateMessageBoard(data)
      .then((result) => {
        this.setState({ submitting: false })
        toast.success('Submitted successfully', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        this.setState({ submitting: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  fetchMessageBoardAlertData = async () => {
    this.setState({ loading: true })
    AdminApi.getMessageBoardAlertDataAdmin()
      .then((result) => {
        if (result && result.data && result.data.data) {
          const messageBoardAlert = result.data.data
          this.setState({
            alert_type: messageBoardAlert.alert_type,
            switch1: messageBoardAlert.active ? true : false,
            message: messageBoardAlert.message,
          })
        }
      })
      .then(() => {
        this.setState({ loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  render() {
    toast.configure()

    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="ManageMessagesAlertsPage" className="mainSection mt-5 pb-5">
            <MDBContainer fluid>
              <MDBRow>
                <MDBCol size="12">
                  <h2>Manage Messaging</h2>
                  <hr />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12">
                  <section className="mx-2 pt-4">
                    <ul
                      className="nav nav-tabs md-tabs"
                      id="myTabMD"
                      role="tablist"
                    >
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className={
                            'nav-link ' +
                            (this.isActive('messages') ? 'active' : '')
                          }
                          id="messages-tab-md"
                          data-toggle="tab"
                          href="#messages-md"
                          role="tab"
                          aria-controls="home-md"
                          aria-selected={this.isActive('messages')}
                          onClick={() => this.change('messages')}
                        >
                          Message Board
                        </a>
                      </li>
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className={
                            'nav-link ' +
                            (this.isActive('alerts') ? 'active' : '')
                          }
                          id="alerts-tab-md"
                          data-toggle="tab"
                          href="#alerts-md"
                          role="tab"
                          aria-controls="profile-md"
                          aria-selected={this.isActive('alerts')}
                          onClick={() => this.change('alerts')}
                        >
                          Alerts
                        </a>
                      </li>
                    </ul>
                    <div
                      className="tab-content card pt-5"
                      id="mymdbTabContentMD"
                    >
                      {this.renderTab('messages')}
                      {this.renderTab('alerts')}
                    </div>
                  </section>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default withRouter(ManageMessagesAlertsPage)
