import AccountAlertsPage from './../../pages/Account/AccountAlertsPage'
import AccountBillingInfoPage from './../../pages/Account/AccountBillingInfoPage'
import AccountProfile from './../../pages/Account/AccountProfile'
import AccountResetPassword from './../../pages/Account/AccountResetPassword'
import AccountTermsPage from './../../pages/Account/AccountTermsPage'
import AddCalendar from './../../pages/AdminCalendar/AddCalendar'
import AddCommissions from './../../pages/AdminCommissions/AddCommissions'
import AddCarrierContest from './../../pages/AdminContests/AddCarrierContest'
import AddLeadProgram from './../../pages/AdminPrograms/AddLeadProgram'
import AddCoverage from './../../pages/AdminCoverages/AddCoverage'
import AddDivision from './../../pages/AdminDivisions/AddDivision'
import AddLeadCompany from './../../pages/AdminLeadOrders/AddLeadCompany'
import AddLeadSources from './../../pages/AdminLeads/AddLeadSources'
import AddPoints from './../../pages/AdminPoints/AddPoints'
import AddProduct from './../../pages/AddProduct/AddProduct'
import AddProspectPage from './../../pages/ManageProspects/AddProspectPage'
import AddResourcePage from './../../pages/ResourceLibrary/AddResourcePage'
import AddResourceTypePage from './../../pages/ResourceLibrary/AddResourceTypePage'
import AgentPackagesPage from './../../pages/AgentPackages/AgentPackagesPage'
import EditTechTools from './../../pages/TechTools/EditTechTools'
import AddTerms from './../../pages/AdminTerms/AddTerm'
import AddUsertype from './../../pages/AdminUsertypes/AddUsertype'
import AdminCalendar from './../../pages/AdminCalendar'
import AdminCoverages from './../../pages/AdminDashboard/AdminDashboardCoverages'
import AdminDashboardCommissions from './../../pages/AdminDashboardComissionLevels'
import AdminDashboardContactInfo from './../../pages/AdminDashboard/AdminDashboardContactInfo'
import AdminDashboardContactsPage from './../../pages/AdminDashboardContactsPage'
import AdminDashboardContentPage from './../../pages/AdminDashboardContentPage'
import AdminDashboardCarrierContests from './../../pages/AdminDashboardCarrierContests'
import AdminDashboardLeadPrograms from './../../pages/AdminDashboardLeadPrograms'
import AdminDashboardLeads from './../../pages/AdminDashboardLeadSources'
import AdminDashboardNewContentPage from './../../pages/AdminDashboardNewContentPage'
import AdminDashboardQuotesPage from './../../pages/AdminDashboardQuotesPage'
import AdminDashboardResourceTypes from './../../pages/AdminDashboard/AdminDashboardResourceTypes'
import AdminPackagesPage from './../../pages/AdminPackages/AdminPackages.page'
import AdminTechTools from './../../pages/TechTools/AdminTechTools'
import AdminDivisions from './../../pages/AdminDashboardDivisions'
import AdminNotificationsEmails from './../../pages/AdminDashboard/AdminDashboardNotificationEmail'
import AdminPoints from './../../pages/AdminUpdatePoints'
import AdminVirtualVault from './../../pages/AdminVirtualVault'
import AdminProducts from './../../pages/AdminDashboardProducts'
import AdminProspects from './../../pages/AdminDashboardProspects'
import AdminReports from './../../pages/AdminReports/AdminReports'
import AdminResources from './../../pages/AdminDashboard/AdminDashboardResources'
import AdminTerms from './../../pages/AdminTerms/AdminTerms.page'
import AdminUsertypes from './../../pages/AdminDashboard/AdminDashboardUserTypes'
import AgentSubsitePage from './../../pages/AgentSubsite/AgentSubsite.page'
import AuthPage from './../../pages/Auth/AuthPage'
import AvApps from './../../pages/AdminReports/AvApplications'
import BackofficeSearch from './../../pages/BackofficeSearch'
import BeastModeSignUpPage from './../../pages/BeastModeSignUp/BeastModeSignUpPage'
import BeastModeSetupPage from './../../pages/BeastModeSetupPage/BeastModeSetupPage'
import CalendarPage from './../../pages/Calendar/CalendarPage'
import CarrierManagementPage from './../../pages/CarrierManagement/CarrierManagementPage'
import CarrierCrudPage from './../../pages/CarrierManagement/CarrierCrudPage'
import CommissionsPage from './../../pages/Commissions/CommissionsPage'
import ContactUs from './../../pages/ContactUs'
import CarrierContestsPage from './../../pages/CarrierContests/CarrierContestsPage'
import DashboardPage from './../../pages/Dashboard/DashboardPage'
import DivisionMessagesPage from './../../pages/DivisionMessages/DivisionMessagesPage'
import EditEmailNotification from './../../pages/AdminEmailNotification/EditEmailNotification'
import EditProductLink from './../../pages/WebsiteInfo/inc/edit-product-link'
import EmailDistributionPage from './../../pages/EmailDistribution/EmailDistributionPage'
import EmailSignature from './../../pages/AgentArea/EmailSignature'
import EnrollmentPage from './../../pages/Enrollment/EnrollmentPage'
import EoPolicyPage from './../../pages/EoPolicyPage/EoPolicyPage'
import FactorMilestones from './../../pages/AdminReports/FactorsMilestones'
import GoToMeeting from './../../pages/AdminReports/GoToMeeting'
import Home from './../../pages/Homepage'
import KpiGoalsPage from './../../pages/KpiGoalsPage/KpiGoalsPage'
import KpiDashboardPage from './../../pages/KpiDashboardPage/KpiDashboardPage'
import KpiTrackingPage from './../../pages/KpiTrackingPage/KpiTrackingPage'
import LeadProgramsPage from './../../pages/LeadPrograms/LeadProgramsPage'
import LeaderboardsPage from './../../pages/Reports/LeaderboardsPage'
import MaintainProducts from './../../pages/WebsiteInfo/MaintainProducts'
import ManageAgentsPage from './../../pages/ManageAgents/ManageAgentsPage'
import ManageMessagingPage from './../../pages/ManageMessages/ManageMessagingPage'
import LeaderGuidelines from './../../pages/ManagerArea/LeaderGuidelinesPage'
import ManagerResources from './../../pages/ManagerArea/Manager-Resources'
import MenuLinks from './../../pages/WebsiteInfo/MenuLinks'
import MyProducts from './../../pages/WebsiteInfo/MyProducts'
import OauthFormsPage from './../../pages/OauthForms/OauthFormsPage'
import OrderLeadsPage from './../../pages/OrderLeads/OrderLeadsPage'
import PointsDetailsPage from './../../pages/PointsDetails/PointsDetailsPage'
import ProductLinks from './../../pages/WebsiteInfo/ProductLinks'
import Products from './../../pages/Products'
import ProspectsPage from './../../pages/ManageProspects/ProspectsPage'
import ReportsPage from './../../pages/Reports/ReportsPage'
import ResourceLibraryPage from './../../pages/ResourceLibrary/ResourceLibraryPage'
import ServiceConsumptionExamplePage from './../../pages/ServiceConsumptionExample/ServiceConsumptionExamplePage'
import SigAgentBenefits from './../../pages/ManagerArea/SigAgentBenefits'
import SignatureAgentBenefits from './../../pages/ManagerArea/SignatureAgentBenefits'
import StateLicensesPage from './../../pages/StateLicensesPage/StateLicensesPage'
import TechToolsPage from './../../pages/TechTools/TechToolsPage'
import TrainingsPage from './../../pages/TrainingPages/TrainingsPage'
import UserReports from './../../pages/AdminReports/UserReports'
import UserTypeReports from './../../pages/AdminReports/UserTypeReports'
import ViewGoToMeeting from './../../pages/AdminReports/inc/ViewGoToMeetings'
import ViewSignatures from './../../pages/AdminTerms/ViewSignatures'
import ViewUserTypeReports from './../../pages/AdminReports/inc/ViewUserTypeReport'
import WebsiteContactRequests from './../../pages/WebsiteInfo/ContactRequests'
import WebsiteInfo from './../../pages/WebsiteInfo/Info'
import WebsiteQuoteRequests from './../../pages/WebsiteInfo/QuoteRequests'
import WelcomePage from './../../pages/Welcome/WelcomePage'
import InternalResources from '../../pages/InternalResource/InternalResource'
import AddTraniningQuiz from '../../pages/TrainingQuizesPage/AddTrainingQuiz.page'

const defaultAuthRoute = {
  name: 'Backoffice',
  path: '/dashboard',
  component: DashboardPage,
  guards: {
    requiresAuth: true,
    reqUsertypeIds: null,
    blockedUsertypeIds: null,
  },
}

const defaultNonAuthRoute = {
  name: 'Home',
  path: '/',
  component: Home,
  guards: {
    requiresAuth: false,
    reqUsertypeIds: null,
    blockedUsertypeIds: null,
  },
}

const defaultOnboardRoute = {
  name: 'Onboarding',
  path: '/welcome',
  component: WelcomePage,
  guards: {
    requiresAuth: true,
    reqUsertypeIds: null,
    blockedUsertypeIds: null,
  },
}

const defaultEnrollRoute = {
  name: 'Subsite Enrollment',
  path: '/:u_login/enroll',
  component: EnrollmentPage,
  guards: {
    requiresAuth: true,
    reqUsertypeIds: null,
    blockedUsertypeIds: null,
  },
}

const routeDefinitions = [
  defaultAuthRoute,
  defaultNonAuthRoute,
  defaultOnboardRoute,
  {
    name: 'Division Messages',
    path: '/division-messages',
    component: DivisionMessagesPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Agent Login',
    path: '/login',
    component: AuthPage,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    component: AuthPage,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Reset Password',
    path: '/reset-password',
    component: AuthPage,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Contact USABG',
    path: '/contact',
    component: ContactUs,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Our Products',
    path: '/products',
    component: Products,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - USABG Contact Requests',
    path: '/contact-requests',
    component: AdminDashboardContactsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - USABG Quote Requests',
    path: '/quote-requests',
    component: AdminDashboardQuotesPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Content',
    path: '/content-pages',
    component: AdminDashboardContentPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Carrier Contests',
    path: '/admin-carrier-contests',
    component: AdminDashboardCarrierContests,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Lead Programs',
    path: '/admin-lead-programs',
    component: AdminDashboardLeadPrograms,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Carrier Contest',
    path: '/add-carrier-contests',
    component: AddCarrierContest,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Lead Program',
    path: '/add-lead-programs',
    component: AddLeadProgram,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Carrier Contests',
    path: '/carrier-contests',
    component: CarrierContestsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Lead Sources',
    path: '/admin-leads',
    component: AdminDashboardLeads,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Lead Source',
    path: '/add-leads',
    component: AddLeadSources,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Commission Levels',
    path: '/admin-commissions',
    component: AdminDashboardCommissions,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Commission Level',
    path: '/add-commission',
    component: AddCommissions,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Agency Info',
    path: '/admin-info',
    component: AdminDashboardContactInfo,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Email Templates',
    path: '/admin-emails',
    component: AdminNotificationsEmails,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Divisions',
    path: '/admin-divisions',
    component: AdminDivisions,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Division',
    path: '/add-division',
    component: AddDivision,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [36, 219, 222],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - System Messages',
    path: '/manage-messaging',
    component: ManageMessagingPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Agent Resources',
    path: '/admin-resources',
    component: AdminResources,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Agent Resource',
    path: '/add-resource',
    component: AddResourcePage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Resource Types',
    path: '/admin-resourceType',
    component: AdminDashboardResourceTypes,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Packages & Features',
    path: '/admin-packages',
    component: AdminPackagesPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Agent Resource Type',
    path: '/add-resourceType',
    component: AddResourceTypePage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Agent Packages',
    path: '/agent-packages',
    component: AgentPackagesPage,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Terms',
    path: '/admin-terms',
    component: AdminTerms,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Term',
    path: '/add-term',
    component: AddTerms,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Usertypes',
    path: '/admin-usertypes',
    component: AdminUsertypes,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Usertype',
    path: '/add-usertype',
    component: AddUsertype,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Agent Tools',
    path: '/admin-techtools',
    component: AdminTechTools,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Agent Tool',
    path: '/edit-techtools',
    component: EditTechTools,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Coverages',
    path: '/admin-coverage',
    component: AdminCoverages,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Coverage',
    path: '/add-coverage',
    component: AddCoverage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Carriers',
    path: '/admin-carriers',
    component: CarrierManagementPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Carrier',
    path: '/admin-carriers/:id',
    pattern: /^\/admin-carriers\/(\d+|new)?$/i,
    component: CarrierCrudPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Products',
    path: '/admin-products',
    component: AdminProducts,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Product',
    path: '/add-product',
    component: AddProduct,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Points',
    path: '/admin-points',
    component: AdminPoints,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Virtual Vault',
    path: '/virtual-vault',
    component: AdminVirtualVault,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Points',
    path: '/add-points',
    component: AddPoints,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Lead Company',
    path: '/add-leadcompany',
    component: AddLeadCompany,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Calendar',
    path: '/admin-calendar',
    component: AdminCalendar,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Management Reports',
    path: '/admin-reports',
    component: AdminReports,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - AV Reports',
    path: '/reports-avapps',
    component: AvApps,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Reporting',
    path: '/reporting/:category/:report?',
    pattern: /^\/reporting\/career-totals(\/([\w|-]+)?)?$/,
    component: ReportsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 222, 93, 38, 90, 226, 227, 228, 130],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Reporting',
    path: '/reporting/:category/:report?',
    pattern: /^\/reporting\/district-totals(\/([\w|-]+)?)?$/,
    component: ReportsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 222, 93, 38, 90, 226, 227, 228],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Reporting',
    path: '/reporting/:category/:report?',
    pattern: /^\/reporting\/region-totals(\/([\w|-]+)?)?$/,
    component: ReportsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220, 38, 90, 226, 227],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Reporting',
    path: '/reporting/:category/:report?',
    pattern: /^\/reporting\/division-totals(\/([\w|-]+)?)?$/,
    component: ReportsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220, 90, 226],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Reporting',
    path: '/reporting/:category/:report?',
    pattern:
      /^\/reporting\/(my-production|my-wallet|downline-production|division-production|agency-production|agency-wallets|referrals|contracting|leaders|subscriptions|internal|agent-ledgers|broker-support)(\/([\w|-]+)?)?$/,
    component: ReportsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Award Milestones',
    path: '/factor-milestones',
    component: FactorMilestones,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - User Report',
    path: '/user-reports',
    component: UserReports,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220, 90],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Usertype Report',
    path: '/usertype-reports',
    component: UserTypeReports,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220, 90],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Usertype Report',
    path: '/:id/view-usertype-reports',
    pattern: /^\/\d+\/view-usertype-reports(\/)?$/i,
    component: ViewUserTypeReports,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220, 90],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Go to Meetings',
    path: '/gotomeetings',
    component: GoToMeeting,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Go to Meetings',
    path: '/view-gotomeetings',
    component: ViewGoToMeeting,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Calendar',
    path: '/add-calendar',
    component: AddCalendar,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220, 90],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Training Calendar',
    path: '/training-calendar',
    component: CalendarPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [91],
    },
    readonly: {
      usertypes: ({ RouteDef, ProfileSrvc }) => {
        if (ProfileSrvc.isA(['affiliate-group'])) {
          if (
            parseInt(ProfileSrvc.get('contracts_completed')) +
              parseInt(ProfileSrvc.get('contracts_awaiting')) <
            1
          )
            return '{usertype} accounts without at least 1 completed contract (includes agent writing code) do not have access to the <strong>{pageName}</strong> Page.'
          return false
        } else if (ProfileSrvc.isA(['associate-agent'])) {
          if (
            parseInt(ProfileSrvc.get('contracts_completed')) +
              parseInt(ProfileSrvc.get('contracts_awaiting')) <
            6
          )
            return '{usertype} accounts without at least 5 completed contracts (includes agent writing code) do not have access to the <strong>{pageName}</strong> Page.'
          return false
        }
        return false
      },
    },
  },
  {
    name: 'Commissions',
    path: '/commissions',
    component: CommissionsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Account Profile',
    path: '/account-profile',
    component: AccountProfile,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Account Password',
    path: '/account-password',
    component: AccountResetPassword,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Account Billing & Benefits',
    path: '/account-billinginfo',
    component: AccountBillingInfoPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Account Terms',
    path: '/account-terms',
    component: AccountTermsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Account Alerts',
    path: '/account-alerts',
    component: AccountAlertsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Email Signature',
    path: '/email-signature',
    component: EmailSignature,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 133, 224, 223],
    },
  },
  {
    name: 'Agent E&O Policy',
    path: '/eo-policy',
    component: EoPolicyPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Getting Started',
    path: '/getting-started',
    component: WelcomePage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Agent KPI Goals',
    path: '/kpi-goals',
    component: KpiGoalsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      requiresOnboarding: true,
    },
    readonly: {
      usertypes: ['affiliate-group', 'associate-agent'],
    },
  },
  {
    name: 'Oauth Integrations',
    path: '/oauth/:integration',
    pattern: /^\/oauth\/(adobe-sign)(\/)?$/i,
    component: OauthFormsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [219],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Order Leads',
    path: '/order-leads',
    component: OrderLeadsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [91],
    },
    readonly: {
      usertypes: ({ RouteDef, ProfileSrvc }) => {
        if (ProfileSrvc.isA(['affiliate-group'])) {
          if (
            parseInt(ProfileSrvc.get('contracts_completed')) +
              parseInt(ProfileSrvc.get('contracts_awaiting')) <
            1
          )
            return '{usertype} accounts without at least 1 completed contract (includes agent writing code) do not have access to the <strong>{pageName}</strong> Page.'
          return false
        } else if (ProfileSrvc.isA(['associate-agent'])) {
          if (
            parseInt(ProfileSrvc.get('contracts_completed')) +
              parseInt(ProfileSrvc.get('contracts_awaiting')) <
            6
          )
            return '{usertype} accounts without at least 5 completed contracts (includes agent writing code) do not have access to the <strong>{pageName}</strong> Page.'
          return false
        }
        return false
      },
    },
  },
  {
    name: 'Agent State Licenses',
    path: '/state-licenses',
    component: StateLicensesPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Recorded Trainings',
    path: '/trainings',
    component: TrainingsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
      requiresOnboarding: true,
    },
    readonly: {
      usertypes: ['affiliate-group', 'associate-agent'],
    },
  },
  {
    name: 'USABG BUCK$ Program',
    path: '/usabg-bucks-program',
    component: PointsDetailsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Service Consumption (REST) Example',
    path: '/service-consumption-example',
    component: ServiceConsumptionExamplePage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [219],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Signature Agent Benefits',
    path: '/signature-agent-benefits',
    component: SignatureAgentBenefits,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Signature Agent Benefits',
    path: '/sig-agent-benefits',
    component: SigAgentBenefits,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Lead Programs',
    path: '/lead-programs',
    component: LeadProgramsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [91],
    },
    readonly: {
      usertypes: ({ RouteDef, ProfileSrvc }) => {
        if (ProfileSrvc.isA(['affiliate-group'])) {
          if (
            parseInt(ProfileSrvc.get('contracts_completed')) +
              parseInt(ProfileSrvc.get('contracts_awaiting')) <
            1
          )
            return '{usertype} accounts without at least 1 completed contract (includes agent writing code) do not have access to the <strong>{pageName}</strong> Page.'
          return false
        } else if (ProfileSrvc.isA(['associate-agent'])) {
          if (
            parseInt(ProfileSrvc.get('contracts_completed')) +
              parseInt(ProfileSrvc.get('contracts_awaiting')) <
            6
          )
            return '{usertype} accounts without at least 5 completed contracts (includes agent writing code) do not have access to the <strong>{pageName}</strong> Page.'
          return false
        }
        return false
      },
    },
  },
  {
    name: 'Leader Resources',
    path: '/leader-resources',
    component: ManagerResources,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 91, 149, 37, 92, 223, 224],
    },
    readonly: {
      usertypes: ['affiliate-leader'],
    },
  },
  {
    name: 'Internal Resources',
    path: '/internal-resources',
    component: InternalResources,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Agent KPI Tracking',
    path: '/kpi-tracking',
    component: KpiTrackingPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [91],
      requiresOnboarding: true,
    },
    readonly: {
      usertypes: ['affiliate-group', 'associate-agent'],
    },
  },
  {
    name: 'Leader Guidelines',
    path: '/leader-guidelines',
    component: LeaderGuidelines,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223, 91, 149, 37, 92, 223, 224],
    },
    readonly: {
      usertypes: ['affiliate-leader'],
    },
  },
  {
    name: 'Email Distribution Lists',
    path: '/email-distribution',
    component: EmailDistributionPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223],
    },
  },
  {
    name: 'Prospects',
    path: '/prospects',
    component: ProspectsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223],
    },
    readonly: {
      usertypes: ['affiliate-leader'],
    },
  },
  {
    name: 'Add New Prospect',
    path: '/add-prospects',
    component: AddProspectPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Manage Prospects',
    path: '/admin-prospects',
    component: AdminProspects,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Team Growth Report',
    path: '/manage-agents',
    component: ManageAgentsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223, 91, 149, 37, 92],
    },
  },
  {
    name: 'Agent - Setup Website Info',
    path: '/website-info',
    component: WebsiteInfo,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223],
    },
  },
  {
    name: 'Agent - Setup Website Products',
    path: '/maintain-products',
    component: MaintainProducts,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223],
    },
  },
  {
    name: 'Agent - Setup Menu Links',
    path: '/menu-links',
    component: MenuLinks,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223],
    },
  },
  {
    name: 'Agent - Setup Website Product Links',
    path: '/product-links',
    component: ProductLinks,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223],
    },
  },
  {
    name: 'Agent - Wesite Contact Requests',
    path: '/website-contact-requests',
    component: WebsiteContactRequests,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223],
    },
  },
  {
    name: 'Agent - Website Quote Requests',
    path: '/website-quote-requests',
    component: WebsiteQuoteRequests,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [111, 223],
    },
  },
  {
    name: 'Onboarding',
    path: '/welcome',
    component: WelcomePage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Agent Resources',
    path: '/agent-resources',
    component: ResourceLibraryPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
    readonly: {
      usertypes: ['affiliate-group'],
    },
  },
  {
    name: 'Agent Tools',
    path: '/tools',
    component: TechToolsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [91],
    },
    readonly: {
      usertypes: ['affiliate-group', 'associate-agent'],
    },
  },
  {
    name: 'Admin - Add New Content',
    path: '/content-pages/new',
    component: AdminDashboardNewContentPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Email Template',
    path: '/:id/edit-email',
    pattern: /^\/\d+\/edit-email(\/)?$/i,
    component: EditEmailNotification,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'View Term Signatures',
    path: '/:id/view-signatures',
    pattern: /^\/\d+\/view-signatures(\/)?$/i,
    component: ViewSignatures,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Agent Profile',
    path: '/:id/account-profile',
    pattern: /^\/\d+\/account-profile(\/)?$/i,
    component: AccountProfile,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Search Results',
    path: '/:str/search/:searchScope',
    pattern: /^\/.+\/search\/(users|states|languages)(\/)?$/i,
    component: BackofficeSearch,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'BeastMode 10x CRM Setup',
    path: '/beast-mode-setup',
    component: BeastModeSetupPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'BeastMode 10x CRM Signup',
    path: '/beast-mode-signup',
    component: BeastModeSignUpPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Agent Products',
    path: '/my-products/:id',
    pattern: /^\/my-products\/\d+(\/)?$/i,
    component: MyProducts,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Content',
    path: '/content-pages/:id/edit',
    pattern: /^\/content-pages\/\d+\/edit(\/)?$/i,
    component: AdminDashboardNewContentPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [35, 36, 219, 220],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Carrier Contest',
    path: '/add-carrier-contests/:id/edit',
    pattern: /^\/add-carrier-contests\/\d+\/edit(\/)?$/i,
    component: AddCarrierContest,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Lead Program',
    path: '/add-lead-programs/:id/edit',
    pattern: /^\/add-lead-programs\/\d+\/edit(\/)?$/i,
    component: AddLeadProgram,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Add New Lead',
    path: '/add-leads/:id/edit',
    pattern: /^\/add-leads\/\d+\/edit(\/)?$/i,
    component: AddLeadSources,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Edit Commission',
    path: '/add-commission/:id/edit',
    pattern: /^\/add-commission\/\d+\/edit(\/)?$/i,
    component: AddCommissions,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Division',
    path: '/add-division/:id/edit',
    pattern: /^\/add-division\/\d+\/edit(\/)?$/i,
    component: AddDivision,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [36, 219, 222],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Resource',
    path: '/add-resource/:id/edit',
    pattern: /^\/add-resource\/\d+\/edit(\/)?$/i,
    component: AddResourcePage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Resource Type',
    path: '/add-resourceType/:id/edit',
    pattern: /^\/add-resourceType\/\d+\/edit(\/)?$/i,
    component: AddResourceTypePage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Term',
    path: '/add-term/:id/edit',
    pattern: /^\/add-term\/\d+\/edit(\/)?$/i,
    component: AddTerms,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Training Quiz',
    path: '/add-training-quiz',
    component: AddTraniningQuiz,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Training Quiz',
    path: '/add-training-quiz/:id/edit',
    pattern: /^\/add-training-quiz\/\d+\/edit(\/)?$/i,
    component: AddTraniningQuiz,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Usertype',
    path: '/add-usertype/:id/edit',
    pattern: /^\/add-usertype\/\d+\/edit(\/)?$/i,
    component: AddUsertype,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Edit Agent Tool',
    path: '/edit-techtools/:id/edit',
    pattern: /^\/edit-techtools\/\d+\/edit(\/)?$/i,
    component: EditTechTools,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: [222, 219, 36, 35],
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Coverage',
    path: '/add-coverage/:id/edit',
    pattern: /^\/add-coverage\/\d+\/edit(\/)?$/i,
    component: AddCoverage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Add New Product',
    path: '/add-product/:id/edit',
    pattern: /^\/add-product\/\d+\/edit(\/)?$/i,
    component: AddProduct,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Points',
    path: '/add-points/:id/edit',
    pattern: /^\/add-points\/\d+\/edit(\/)?$/i,
    component: AddPoints,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Admin - Update Calendar',
    path: '/add-calendar/:id/edit',
    pattern: /^\/add-calendar\/\d+\/edit(\/)?$/i,
    component: AddCalendar,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Update Product Links',
    path: '/product-links/:id/edit',
    pattern: /^\/product-links\/\d+\/edit(\/)?$/i,
    component: EditProductLink,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Update My Products',
    path: '/my-products/:id/edit',
    pattern: /^\/my-products\/\d+\/edit(\/)?$/i,
    component: EditProductLink,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Update My Prospects',
    path: '/add-prospects/:id/edit',
    pattern: /^\/add-prospects\/\d+\/edit(\/)?$/i,
    component: AddProspectPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
    readonly: {
      usertypes: ['affiliate-leader'],
    },
  },
  {
    name: 'Leader Boards',
    path: '/leaderboards',
    component: LeaderboardsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Agent KPI Dashboard',
    path: '/kpi-dashboard',
    component: KpiDashboardPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [91],
    },
    readonly: {
      usertypes: ['affiliate-group', 'associate-agent'],
    },
  },
  {
    name: 'Reporting',
    path: '/reporting/:category/:report?',
    pattern: /^\/reporting\/kpi-report(\/([\w|-]+)?)?$/,
    component: ReportsPage,
    guards: {
      requiresAuth: true,
      reqUsertypeIds: null,
      blockedUsertypeIds: [91],
    },
    readonly: {
      usertypes: ['affiliate-group', 'associate-agent'],
    },
  },
  {
    name: 'Subsite Enrollment',
    path: '/:u_login/enroll',
    pattern: /^\/[A-Za-z0-9-]+\/enroll(\/)?$/i,
    component: EnrollmentPage,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
  {
    name: 'Subsite',
    path: '/:u_login/:pageName?',
    pattern: /^\/[A-Za-z0-9-]+(\/((\w|-)+)?)?$/i,
    component: AgentSubsitePage,
    guards: {
      requiresAuth: false,
      reqUsertypeIds: null,
      blockedUsertypeIds: null,
    },
  },
]

const RouteMap = {
  defaultAuthRoute,
  defaultNonAuthRoute,
  defaultOnboardRoute,
  defaultEnrollRoute,
  routes: routeDefinitions,
}

export default RouteMap
