import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBAlert, MDBBtn, MDBIcon, MDBBtnGroup, MDBCollapse } from 'mdbreact'
import { UIInputCheckbox } from './../../../../components/forms/form-fields'
import LGCStore from './../../store/LeaderGoalCard.store'
import './LeaderGoalsForecast.scss'

const DEFAULT_GOAL = 25,
  GROWTH_GOALS = [40, 35, 30, 25, 20, 15]

const LeaderGoalsForecast = ({ viewMode, ownerName }) => {
  const { setGrowthGoal, getGoalYear, getGrowthGoal } = LGCStore,
    growthGoal = getGrowthGoal(),
    goalYear = getGoalYear(),
    isNoGoal =
      goalYear &&
      goalYear < new Date().getFullYear() &&
      typeof growthGoal === 'undefined',
    isEmptyGoal =
      goalYear &&
      goalYear >= new Date().getFullYear() &&
      typeof growthGoal === 'undefined'

  const [isChecked, setIsChecked] = useState(false)

  return (
    <div id="LeaderGoalsForecastComponent">
      <MDBCollapse
        isOpen={viewMode === 'goals' && LGCStore.canEnableAutoForecast()}
      >
        <MDBAlert color="warning">
          This year hasn't completed yet. We can average our your sales to help
          settings next year's goal.
          <div style={{ padding: '4px' }}>
            <UIInputCheckbox
              label={<strong>Auto-forecast remainder of year?</strong>}
              name={`should_autoforecast`}
              id={`should_autoforecast`}
              onChange={(evt) => {
                setIsChecked(!isChecked)
                if (!isChecked) LGCStore.enableAutoForecast()
                else LGCStore.disableAutoForecast()
              }}
              checked={isChecked}
            />
          </div>
        </MDBAlert>
      </MDBCollapse>
    </div>
  )
}

export default observer(LeaderGoalsForecast)
