import BaseModelFactory from './base.factory'
import UserMessageService from './../services/UserMessage.service'
import UserMessage from './../models/user-message.model'

class UserMessageFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: UserMessageService, Model: UserMessage }
    )
  static findAll = async () =>
    await this._findAllBy(
      {},
      { Service: UserMessageService, Model: UserMessage }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: UserMessageService, Model: UserMessage }
    )
  static findAllByUserId = async (id) =>
    await this._findAllBy(
      { user_id: id },
      { Service: UserMessageService, Model: UserMessage }
    )
  static findAllByAuthorId = async (id) =>
    await this._findAllBy(
      { author_id: id },
      { Service: UserMessageService, Model: UserMessage }
    )
  static create = (payload) =>
    this._create(payload, { Service: UserMessageService, Model: UserMessage })
}

export default UserMessageFactory
