import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { LedgerDetail } from './../shared'
import LeadCreditPurchaseModal from './../../modals/LeadCreditPurchaseModal'
import LedgerNotes from './../LedgerNotes/LedgerNotes.component'
import TransactionHistory from './../TransactionHistory/TransactionHistory.component'
import EditTransferForm from './../EditTransferForm/EditTransferForm.component'
import TransfersBar from './../TransfersBar/TransfersBar.component'
import { UIInput, UIInputButton } from './../../forms/form-fields'
import {
  MDBCollapse,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import Store from './../store'
import UsertypeService from './../../../shared/services/Usertype.service'
import UserProfileService from './../../../shared/services/UserProfile.service'

import './AgentLedger.component.scss'

const ucwords = (str) =>
  str.replace(/^([a-z])|[\s_]+([a-z])/g, ($1) => $1.toUpperCase())

const AgentLedger = ({ layout, onOpen, onClose, toast }) => {
  const [isPurchasing, setIsPurchasing] = useState(false),
    onOpenLedger = onOpen && typeof onOpen === 'function' ? onOpen : () => {},
    onCloseLedger =
      onClose && typeof onClose === 'function' ? onClose : () => {}

  const isAdminLayout = layout === 'admin',
    // ledgerExists = !Store.Ledger?.isNew(),
    // [formValidity, setFormValidity] = useState({}),
    [agentSearch, setAgentSearch] = useState('')

  const onSubmit = async (event) => {
    event.preventDefault()

    // if (Object.values(formValidity).filter((v) => v === false).length) {
    //   // setShowValidity(true)
    //   return false
    // }

    Store.isSaving = true
    await Store.Ledger.save()
    Store.Ledgers = Store.Ledgers.map((AL) =>
      parseInt(AL.id()) !== parseInt(Store.Ledger.id()) ? AL : Store.Ledger
    )
    Store.isSaving = false

    return false
  }

  const onAgentSelected = async (Agent) => {
    Store.edit(await Store.findOrCreateLedgerByAgent(Agent))
    if (Store.Ledger) onOpenLedger(Store.Ledger.id())
  }

  const onSearchKeyDown = (evt) => {
    if (evt.key === 'Enter' && agentSearch.length > 0) {
      Store.searchAgents(agentSearch)
      evt.preventDefault()
      return false
    }
  }

  const openPurchaseModal = async () => {
    if (!isPurchasing) {
      setIsPurchasing(true)
      LeadCreditPurchaseModal.open({
        title: 'Purchase USABG BUCK$',
        user: await UserProfileService.instance(),
        ledger: Store.Ledger,
      }).finally(() => {
        Store.Ledger.reload()
        Store.searchAgentLedgerTransactions({
          pagination: Store.ledgerTransactionPagination,
        })
        setIsPurchasing(false)
      })
    }
  }

  const renderHeader = () => {
    return (
      <MDBCardHeader>
        <div className="row">
          <div className="col">
            {layout !== 'agent' && Store.Ledger?.isNew()
              ? 'Create Agent Wallet'
              : 'Agent Wallet'}
          </div>
          <div className="col">
            {isAdminLayout ? (
              <button
                style={{ marginLeft: 'auto' }}
                type="button"
                onClick={() => {
                  Store.Ledger = null
                  Store.Transaction = null
                  onCloseLedger()
                }}
              >
                <MDBIcon icon="times" />
              </button>
            ) : UserProfileService.isA([
                'divisional-leader',
                'regional-leader',
                'district-leader',
                'system-admin',
                'internal-admin',
                'internal-staff',
              ]) ? (
              <MDBBtn onClick={() => openPurchaseModal()}>
                {isPurchasing ? (
                  <>
                    Purchasing&nbsp;<i className="fa fa-spin fa-spinner"></i>
                  </>
                ) : (
                  'purchase usabg BUCK$'
                )}
              </MDBBtn>
            ) : (
              <></>
            )}
          </div>
        </div>
      </MDBCardHeader>
    )
  }

  const renderAgentSearch = () => {
    if (layout !== 'agent' && Store.Ledger?.isNew()) {
      const SearchInput = () => {
        return (
          <UIInput
            type="text"
            label="Search Agents"
            value={agentSearch}
            onKeyDown={onSearchKeyDown}
            onChange={(e) => {
              if (agentSearch !== e.target.value) setAgentSearch(e.target.value)
            }}
            style={{ color: '#fff' }}
            required={true}
            rules={{ minLength: 3 }}
          />
        )
      }

      const SearchButton = () => {
        return (
          <>
            <MDBBtn
              disabled={
                Store.isLoading ||
                Store.isSearching ||
                !agentSearch ||
                agentSearch.length < 3
              }
              onClick={() => Store.searchAgents(agentSearch)}
              className="search-btn"
            >
              <MDBIcon icon="search" />
            </MDBBtn>
          </>
        )
      }

      return (
        <form onSubmit={onSubmit} noValidate>
          <UIInputButton
            input={SearchInput()}
            button={SearchButton()}
            btnPosition={'right'}
          />
          <ul className="agent-search-results">
            {(Store?.AgentResults || []).map((Agent, idx) => (
              <li
                onClick={() => onAgentSelected(Agent)}
                key={`search-result-row-${idx}`}
              >
                <div className="agent-name">
                  {Agent.get('u_fname')} {Agent.get('u_lname')}
                </div>
                <div className="agent-usertype">
                  {ucwords(
                    Agent.get('usertype_id')
                      ? UsertypeService.idToName(
                          Agent.get('usertype_id')
                        ).replace(/-/g, ' ')
                      : ''
                  )}
                </div>
                <div className="agent-email">{Agent.get('u_email')}</div>
              </li>
            ))}
          </ul>
        </form>
      )
    }

    return <></>
  }

  const renderLedgerDetails = () => {
    if (layout !== 'agent' && Store.Ledger?.isNew()) return <></>

    return (
      <MDBRow>
        <MDBCol size="12">
          <LedgerDetail
            ledgerId={Store.Ledger?.id()}
            agent={Store.getAgentNameByLedger(Store.Ledger)}
            balance={Store.Ledger?.get('balance')}
            updated_at={Store.Ledger?.get('updated_at')}
          ></LedgerDetail>
        </MDBCol>
      </MDBRow>
    )
  }

  const renderTransferOptions = () => {
    if (!(layout !== 'agent' && Store.Ledger?.isNew()))
      return <TransfersBar layout={layout} toast={toast} />
  }

  const renderLedgerComponent = () => {
    if (!(layout !== 'agent' && Store.Ledger?.isNew()))
      return (
        <>
          <MDBRow>
            <MDBCol className="mb-4">
              <LedgerNotes layout={layout} />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="mb-4">
              <EditTransferForm
                onClose={() => {
                  if (Store.Ledger) onOpenLedger(Store.Ledger.id())
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="mb-4">
              <TransactionHistory
                toast={toast}
                layout={layout}
                onSelect={(transactionId) => {
                  if (Store.Ledger)
                    onOpenLedger(Store.Ledger.id(), transactionId)
                }}
              />
            </MDBCol>
          </MDBRow>
        </>
      )
    return <></>
  }

  const shouldOpen =
    !!Store.Ledger && (Store.Ledger?.id() > 0 || Store.Ledger?.isNew())

  return (
    <div id="AgentLedgerComponent">
      <MDBCollapse isOpen={shouldOpen}>
        <MDBCard>
          {renderHeader()}
          <MDBCardBody>
            {renderAgentSearch()}
            {renderLedgerDetails()}
            {renderTransferOptions()}
          </MDBCardBody>
        </MDBCard>
        {renderLedgerComponent()}
      </MDBCollapse>
    </div>
  )
}

export default observer(AgentLedger)
