export const GoalBlocks = {
  goal_earnings: {
    field: 'goal_earnings',
    title: 'Annual income goal',
    value: undefined,
    block: 1,
    formula: null,
    questionId: 'A',
    mask: {
      prefix: '$',
    },
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(2) : val),
  },
  goal_apps: {
    field: 'goal_apps',
    title: 'Apps needed per year',
    value: undefined,
    block: 1,
    formula: 'A/(E/G)',
    questionId: 'B',
    decimalScale: 0,
    mask: null,
    display: (val) =>
      !isNaN(val) ? Math.round(parseFloat(val).toFixed(3)) : val,
  },
  goal_clients: {
    field: 'goal_clients',
    title: 'Clients needed per year',
    value: undefined,
    block: 1,
    formula: 'A/((G/H)*(E/G))',
    questionId: 'C',
    mask: null,
    decimalScale: 0,
    display: (val) =>
      !isNaN(val) ? Math.round(parseFloat(val).toFixed(3)) : val,
  },
  goal_calls: {
    field: 'goal_calls',
    title: 'Calls needed per year',
    value: undefined,
    block: 1,
    formula: 'C*(I/H)',
    questionId: 'D',
    mask: null,
    decimalScale: 0,
    display: (val) =>
      !isNaN(val) ? Math.round(parseFloat(val).toFixed(3)) : val,
  },
  prev_earnings: {
    field: 'prev_earnings',
    title: 'Last 12 month income',
    value: undefined,
    block: 2,
    formula: null,
    questionId: 'E',
    mask: {
      prefix: '$',
    },
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(2) : val),
  },
  prev_points: {
    field: 'prev_points',
    title: 'Last 12 month points',
    value: undefined,
    block: 2,
    formula: null,
    questionId: 'F',
    display: (val) => (!isNaN(val) ? parseInt(val) : val),
  },
  prev_apps: {
    field: 'prev_apps',
    title: 'Last 12 month total num of apps',
    value: undefined,
    block: 2,
    formula: null,
    questionId: 'G',
    display: (val) => (!isNaN(val) ? parseInt(val) : val),
  },
  prev_clients: {
    field: 'prev_clients',
    title: 'Last 12 month total num of clients',
    value: undefined,
    block: 2,
    formula: null,
    questionId: 'H',
    display: (val) => (!isNaN(val) ? parseInt(val) : val),
  },
  prev_calls: {
    field: 'prev_calls',
    title: 'Last 12 month total calls / leads',
    value: undefined,
    block: 2,
    formula: null,
    questionId: 'I',
    display: (val) => (!isNaN(val) ? parseInt(val) : val),
  },
  metric_calls_to_client: {
    field: 'metric_calls_to_client',
    title: 'Number of calls to earn a client',
    value: undefined,
    block: 3,
    formula: 'I/H',
    questionId: 'J',
    decimalScale: 0,
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(3) : val),
  },
  metric_apps_per_client: {
    field: 'metric_apps_per_client',
    title: 'Average number of apps per client',
    value: undefined,
    block: 3,
    formula: 'G/H',
    questionId: 'K',
    decimalScale: 0,
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(3) : val),
  },
  metric_avg_app_earning: {
    field: 'metric_avg_app_earning',
    title: 'Average application earnings',
    value: undefined,
    block: 3,
    formula: 'E/G',
    questionId: 'L',
    mask: {
      prefix: '$',
    },
    decimalScale: 2,
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(3) : val),
  },
  metric_avg_app_pts: {
    field: 'metric_avg_app_pts',
    title: 'Average application points',
    value: undefined,
    block: 3,
    formula: 'F/G',
    questionId: 'M',
    decimalScale: 0,
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(3) : val),
  },
  metric_avg_client_earnings: {
    field: 'metric_avg_client_earnings',
    title: 'Average earnings per client',
    value: undefined,
    block: 3,
    formula: 'K*L',
    questionId: 'N',
    mask: {
      prefix: '$',
    },
    decimalScale: 2,
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(3) : val),
  },
  prev_enrolled_agents: {
    field: 'prev_enrolled_agents',
    title: 'Last 12 months enrolled agents',
    value: undefined,
    block: 2,
    formula: null,
    questionId: 'O',
    guards: ['recruiter-group'],
    display: (val) => (!isNaN(val) ? parseInt(val) : val),
  },
  metric_avg_prev_enrolled_agents: {
    field: 'metric_avg_prev_enrolled_agents',
    title: 'Average enrolled agents per week',
    value: undefined,
    block: 3,
    formula: 'O/52',
    questionId: 'P',
    decimalScale: 2,
    guards: ['recruiter-group'],
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(3) : val),
  },
  goal_points: {
    field: 'goal_points',
    title: 'Annual points goal',
    value: undefined,
    block: 4,
    formula: 'F*1.25',
    questionId: 'Q',
    guards: ['recruiter-group'],
    min: 'formula',
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(2) : val),
  },
  goal_enrolled_agents: {
    field: 'goal_enrolled_agents',
    title: 'Annual enrolled agent goal',
    value: undefined,
    block: 4,
    formula: 'O*1.25',
    questionId: 'R',
    guards: ['recruiter-group'],
    min: 'formula',
    minValue: undefined,
    display: (val) => (!isNaN(val) ? parseFloat(val).toFixed(2) : val),
  },
}

export const ModifierBlocks = {
  add_fact_finders: {
    field: 'add_fact_finders',
    title: 'Additional Fact Finders Per Day',
    value: undefined,
    block: 4,
    formula: null,
    questionId: null,
  },
  add_appts_set: {
    field: 'add_appts_set',
    title: 'Additional Appointments Per Day',
    value: undefined,
    block: 4,
    formula: null,
    questionId: null,
  },
  add_calls: {
    field: 'add_calls',
    title: 'Additional Calls Per Day',
    value: undefined,
    block: 4,
    formula: null,
    questionId: null,
  },
  add_spoken_to: {
    field: 'add_spoken_to',
    title: 'Additional Customer Spoken To Per Day',
    value: undefined,
    block: 4,
    formula: null,
    questionId: null,
  },
}

export const blockTitles = {
  1: 'Goal Forecasting',
  2: 'Collect Your Historical Data',
  3: 'Know Your Numbers',
  4: 'What if I ...',
}
