import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PageHeading from '../../components/shared/PageHeading.component'
import CarrierGrowthReportWidget from './components/GrowthReportWidget/CarrierGrowthReportWidget.component'

import './CarrierGrowthReport.page.scss'

const CarrierGrowthReportPage = () => {
  const props = {}

  return (
    <DashboardLayout>
      <main
        id="CarrierGrowthReportPage"
        className="adminStyle mainSection mt-5 pb-5"
      >
        <MDBContainer fluid className="mt-2">
          <PageHeading
            label="Carrier Growth Report"
            url={props?.match?.url}
            from={props?.location?.state?.from}
            goTo={(url) =>
              props.history.push(url, {
                from: '/reports/carrier-growth-report',
              })
            }
          />
          <MDBRow>
            <MDBCol size="12">
              <CarrierGrowthReportWidget />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </DashboardLayout>
  )
}

export default CarrierGrowthReportPage
