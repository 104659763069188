import AdminApi from './../../api/admin-api/admin-api'
import PermissionService from './Permission.service'
import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/users'

const getUserDetails = async (userId) => {
  return new Promise((resolve, reject) => {
    AdminApi.getUserDataById(userId)
      .then((results) => {
        try {
          results = results.data.data
        } catch (ex) {
          results = false
        }
        if (results) resolve(results)
        else
          reject(
            `You do not have access to any user accounts by the selected id: ${userId}.`
          )
      })
      .catch((error) => reject(error.message))
  })
}

const canUser = async (permission, userId) => {
  return await PermissionService.canUser(permission, userId)
}

const getUserMetas = async (metaKey, userId) => {
  if (userId) {
    let response = await AdminApi.getUserMetas(userId, metaKey)
    if (response && response.data.data) return response.data.data
  }

  console.error(
    'ERROR: Unable to retrieve user_metas with user_id in UserService.',
    { user_id: userId, meta_key: metaKey }
  )
  return undefined
}

const upsertUserMeta = async (metaKey, metaValue, userId) => {
  if (userId) {
    let response = await AdminApi.upsertUserMeta(userId, metaKey, metaValue)
    if (response && response.data.data) return response.data.data
  }

  console.error(
    'ERROR: Unable to upsert user_meta with user_id in UserService.',
    { user_id: userId, meta_key: metaKey }
  )
  return undefined
}

const UserService = {
  getUserDetails: getUserDetails,
  canUser: canUser,
  getUserMetas: getUserMetas,
  upsertUserMeta: upsertUserMeta,

  search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  get: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, id, req, opts),
  store: async (data, opts) =>
    RestApi.request('POST', srvcEndpoint, data, opts),
  update: async (id, data, opts) =>
    RestApi.request('PUT', srvcEndpoint, id, data, opts),
  delete: async (id, opts) => RestApi.request('DELETE', srvcEndpoint, id, opts),

  enroll: async (uplineId, req, opts) =>
    RestApi.request(
      'POST',
      srvcEndpoint,
      uplineId,
      { ...req, ...(opts && opts?.verify ? { verifyOnly: 1 } : {}) },
      {
        endpoint: `/${uplineId}/enroll`,
      }
    ),
  complete: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/complete`,
    }),
  getPipLevel: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, {
      endpoint: `/${id}/get-pip-level`,
    }),
  getPipLevelByYear: async (id, year, opts) =>
    RestApi.request(
      'GET',
      srvcEndpoint,
      {},
      {
        endpoint: `/${id}/get-pip-level/${year}`,
      }
    ),
  getTeamPipLevel: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, {
      endpoint: `/${id}/get-team-pip-level`,
    }),
  getTeamPipLevelByYear: async (id, year, opts) =>
    RestApi.request(
      'GET',
      srvcEndpoint,
      {},
      {
        endpoint: `/${id}/get-team-pip-level/${year}`,
      }
    ),
  getDownline: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/${id}/downline` }),
  getDownlineLeaders: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, {
      endpoint: `/${id}/downline/leaders`,
    }),
  getUpline: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/${id}/upline` }),
  createBlankCheck: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/create-blank-check`,
    }),
  uploadBlankCheck: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/upload-blank-check`,
    }),
  uploadStateLicense: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/upload-state-license`,
    }),
  uploadEopolicy: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/upload-eopolicy`,
    }),
  uploadHeadshot: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/upload-headshot`,
    }),
  uploadDriverLicense: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/driver-license`,
    }),
  resetUplineSignature: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/reset-upline-signature`,
    }),
  hasCompletedTrainings: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/has-completed-trainings`,
    }),
  hasCompletedEnroll: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/has-completed-enroll`,
    }),
  hasCompletedTerms: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/has-completed-terms`,
    }),
  hasCompletedGoals: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/has-completed-goals`,
    }),
  requestCorporateEmail: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/request-corporate-email`,
    }),
  deleteCorporateEmail: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/delete-corporate-email`,
    }),
  resyncCorporateEmail: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/resync-corporate-email`,
    }),
  signTerms: async (id, req, opts) =>
    RestApi.request('POST', srvcEndpoint, id, req, {
      endpoint: `/${id}/sign-terms`,
    }),
  getRegion: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/${id}/region` }),
  getDistrict: async (id, req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/${id}/district` }),
}

export default UserService
