import React, { useState, useEffect } from 'react'
import env from './../../../environments/environment'
import useStateWithCallback from './../../../shared/hooks/useStateWithCallback.hook'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import {
  MDBFileInput,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import { b64toBlob } from './../../../shared/utilities/b64toBlob.function'
import moment from 'moment'
import UserEopolicyService from './../../../shared/services/UserEopolicy.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserService from './../../../shared/services/User.service'
import EopolicyStore from './Eopolicy.store'
import DatePicker from 'react-datepicker'
import AgentProfileStore from './../AgentProfileIntake/AgentProfile.store'

import exErrorOmission from './assets/imgs/img.errors-omissions.png'
import './AgentEandOManager.scss'

let debounceTimer

const convertDate = (value) => {
  value = value && typeof value === 'string' ? value.split('T')[0] : value

  if (value && typeof value === 'string')
    return moment(value, 'YYYY-MM-DD').toDate()
  if (value && typeof value === 'object') return value
}

const AgentEandOManager = () => {
  useEffect(() => {
    EopolicyStore.eopolicy = null
    setEopolicy(null)

    EopolicyStore.fetchEopolicy().finally(() => {
      AgentProfileStore.setValidity(
        'eopolicy',
        getIsValid({ ...(toJS(EopolicyStore.eopolicy) || {}) })
      )
      setEopolicy(EopolicyStore.eopolicy)
    })
  }, [])

  const debouncedSave = async (data) => {
    if (debounceTimer) {
      window.clearTimeout(debounceTimer)
      debounceTimer = undefined
    }

    debounceTimer = window.setTimeout(() => {
      save(data)
    }, 800)
  }

  const [eopolicy, setEopolicy] = useStateWithCallback(
    EopolicyStore.eopolicy || {},
    (data) => {
      getIsDatesValid(data) && debouncedSave(data)
    }
  )
  const [showModal, setShowModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const EffectiveDate = React.forwardRef((props, ref) => (
      <MDBInput label="Effective Date" ref={ref} {...props} />
    )),
    ExpiryDate = React.forwardRef((props, ref) => (
      <MDBInput label="Expiration Date" ref={ref} {...props} />
    ))

  const effective_date = convertDate(eopolicy?.effective_date)
      ? moment(convertDate(eopolicy?.effective_date)).format('MM/DD/YYYY')
      : eopolicy?.effective_date,
    expiration_date = convertDate(eopolicy?.expiration_date)
      ? moment(convertDate(eopolicy?.expiration_date)).format('MM/DD/YYYY')
      : eopolicy?.expiration_date

  const deleteEopolicyDoc = async () => {
    setIsDeleting(true)
    setEopolicy((prevState) => ({ ...prevState, eopolicy_pdf: '' }))
    setShowModal(false)
    await UserEopolicyService.update(eopolicy?.id, { eopolicy_pdf: null })
    AgentProfileStore.setValidity(
      'eopolicy',
      getIsValid({ ...eopolicy, eopolicy_pdf: '' })
    )
    setIsDeleting(false)
  }

  const downloadPdf = () => {
    if (eopolicy?.eopolicy_pdf)
      window.open(
        `https://firebasestorage.googleapis.com/v0/b/${
          env.integrations.firebase.config.storageBucket
        }/o/${encodeURIComponent(
          decodeURIComponent(eopolicy?.eopolicy_pdf)
        )}?alt=media`
      )
  }

  const getIsDatesValid = (eo) => {
    eo = eo && typeof eo === 'object' ? eo : {}

    let { effective_date, expiration_date } = eo

    effective_date =
      effective_date && typeof effective_date === 'string'
        ? moment(`${effective_date}`.split('T').shift()).toDate()
        : effective_date
    expiration_date =
      expiration_date && typeof expiration_date === 'string'
        ? moment(`${expiration_date}`.split('T').shift()).toDate()
        : expiration_date

    if (effective_date && expiration_date) {
      if (
        typeof effective_date === 'object' &&
        typeof expiration_date === 'object'
      ) {
        if (effective_date.getTime() >= expiration_date.getTime()) return false
      }
    }

    return true
  }

  const getIsValid = (eo) => {
    let isValid = false

    if ((eo = eo = eo ? eo : eopolicy)) {
      isValid = !!(
        eo?.id &&
        eo?.carrier_name &&
        eo?.per_coverage_amt &&
        eo?.agg_coverage_amt &&
        eo?.eopolicy_num &&
        eo?.eopolicy_pdf &&
        eo?.effective_date &&
        eo?.expiration_date
      )
    }

    if (isValid) return getIsDatesValid(eo)

    return false
  }

  const save = async (data) => {
    data = data && typeof data === 'object' ? data : {}

    let required = {
      carrier_name: 'E&O Policy Carrier',
      eopolicy_num: 'E&O Policy Number',
      per_coverage_amt: 'Coverage Amount (each occurrence)',
      agg_coverage_amt: 'Coverage Amount (aggregate)',
      effective_date: 'Effective Date',
      expiration_date: 'Expiration Date',
    }
    if (
      (required = Object.keys(required).filter(
        (r) => !data.hasOwnProperty(r) || !data[r]
      )).length
    ) {
      if (required.length > 0) return
    }

    let result
    try {
      result = await EopolicyStore.saveEopolicy(eopolicy)
    } catch (ex) {
      console.error('Faield to save eo policy update. ', ex)
    }

    if (result && result?.id && !eopolicy?.id)
      setEopolicy((prevState) => ({ ...prevState, ...result }))
  }

  const onSelectFile = (file) => {
    if (!eopolicy?.id) return

    const reader = new FileReader()

    reader.addEventListener('load', async () => {
      eopolicy['eopolicy_pdf_src'] = {
        filename: file?.name,
        data: reader.result,
      }
      setEopolicy(eopolicy)

      // Extract content-type from complete base64 payload.
      let match = eopolicy['eopolicy_pdf_src'].data
        .split(',')[0]
        .match(/\w+\/\w+/)
      match = match && match.shift()

      // Extract raw base64 data w/out content-type.
      let base64 = eopolicy['eopolicy_pdf_src'].data.split(',')[1]

      // Build payload to send file to the server.
      const formData = new FormData()
      formData.append('assumed_user_id', UserProfileService.getUserId(true))
      formData.append('eopolicy_pdf', b64toBlob(base64, match))
      formData.append('eopolicy_pdf_filename', file.name)
      formData.append('eopolicy_pdf_id', eopolicy?.id)

      try {
        await UserService.uploadEopolicy(
          UserProfileService.getUserId(),
          formData
        )
        UserEopolicyService.get(eopolicy?.id).then((res) => {
          AgentProfileStore.setValidity(
            'eopolicy',
            getIsValid({ ...eopolicy, eopolicy_pdf: res && res?.eopolicy_pdf })
          )
          setEopolicy((ps) => ({
            ...ps,
            eopolicy_pdf: res && res?.eopolicy_pdf,
          }))
        })
      } catch (ex) {
        console.error('Failed to upload: ', ex)
      }
    })

    reader.readAsDataURL(file)
  }

  const onChange = (evt) => {
    if (['effective_date', 'expiration_date'].indexOf(evt.target.name) > -1) {
      let curr = eopolicy[evt.target.name],
        change = evt.target.value

      if (
        (curr && !change) ||
        (!curr && change) ||
        moment(convertDate(curr)).format('MM/DD/YYYY') !==
          moment(convertDate(change)).format('MM/DD/YYYY')
      ) {
        setEopolicy((prevState) => {
          prevState = { ...prevState, [evt.target.name]: evt.target.value }
          AgentProfileStore.setValidity('eopolicy', getIsValid())
          return prevState
        })
      }
    } else if (
      !eopolicy.hasOwnProperty(evt.target.name) ||
      eopolicy[evt.target.name] !== evt.target.value
    ) {
      setEopolicy((prevState) => {
        prevState = { ...prevState, [evt.target.name]: evt.target.value }
        AgentProfileStore.setValidity('eopolicy', getIsValid())
        return prevState
      })
    }
  }

  const currencyKeypress = (event) => {
    let name = event.target.name,
      isNumeric = event.which >= 48 && event.which <= 57,
      isDecimal = parseInt(event.which) === 46,
      decimalExists =
        eopolicy.hasOwnProperty(name) &&
        `${eopolicy.per_coverage_amt}`.indexOf('.') > -1

    if ((isNumeric || isDecimal) && !decimalExists) return true

    if (
      isNumeric &&
      decimalExists &&
      `${eopolicy[name]}`.split('.')[1].length < 2
    )
      return true

    event.preventDefault()
    return false
  }

  const renderFileOutput = () => {
    return (
      <div className="img-wrapper">
        {eopolicy?.eopolicy_pdf &&
        `${eopolicy.eopolicy_pdf}`.trim().length > 0 ? (
          <>
            <div onClick={() => setShowModal(true)} className="delete-btn">
              <MDBIcon icon="trash" className="remove" />
            </div>
            <div className="img">
              <img src={exErrorOmission} alt="Agent's E&O Policy" />
              <small>Uploaded</small>
            </div>
            <div onClick={() => downloadPdf()} className="download-btn">
              <MDBIcon icon="download" />
              &nbsp;Download Your Personal E&O Policy
            </div>
          </>
        ) : (
          <>
            <MDBFileInput
              disabled={!eopolicy?.id}
              name="eopolicy_pdf"
              accept="image/*"
              getValue={(value) => onSelectFile(value[0])}
              btnTitle={'Upload your Personal E&O Certificate'}
              textFieldTitle={'Your Policy File'}
            />
          </>
        )}
      </div>
    )
  }

  const renderGroupPolicy = () => {
    if (UserProfileService.isA(['affiliate-group', 'associate-group']))
      return <></>

    if (EopolicyStore.getGroupUrl())
      return (
        <div className="group-policy-wrapper">
          <a
            className="btn view-group-policy-btn"
            href={EopolicyStore.getGroupUrl()}
            target="_BLANK"
            rel="noopener noreferrer"
          >
            View Group E&O Certificate
          </a>
        </div>
      )
  }

  // if (EopolicyStore.eopolicy === null) {
  //   EopolicyStore.eopolicy = {}
  //   EopolicyStore.fetchEopolicy().finally(() => {
  //     AgentProfileStore.setValidity(
  //       'eopolicy',
  //       getIsValid({ ...(toJS(EopolicyStore.eopolicy) || {}) })
  //     )
  //     setEopolicy(EopolicyStore.eopolicy)
  //   })
  // }

  const renderForm = () => {
    if (EopolicyStore.eopolicy === null) return <></>

    const isDatesValid = getIsDatesValid(eopolicy)
    const minDate = moment(
      eopolicy?.effective_date && typeof eopolicy.effective_date === 'object'
        ? eopolicy.effective_date
        : new Date()
    )
      .add(1, 'days')
      .toDate()

    return (
      <>
        <MDBCol size="12" sm="9">
          <MDBRow>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper input-select-wrapper',
                  !eopolicy?.carrier_name ? 'active-input' : '',
                ].join(' ')}
              >
                <MDBInput
                  label="E&O Policy Carrier"
                  name="carrier_name"
                  value={eopolicy?.carrier_name}
                  onChange={(event) =>
                    onChange({
                      target: {
                        name: 'carrier_name',
                        value: event.target.value,
                      },
                    })
                  }
                  required
                />
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper',
                  !eopolicy?.eopolicy_num ? 'active-input' : '',
                  !eopolicy?.id && !eopolicy?.carrier_name
                    ? 'disabled-input'
                    : '',
                  eopolicy?.id && !eopolicy?.eopolicy_num
                    ? 'invalid-input'
                    : '',
                ].join(' ')}
              >
                <MDBInput
                  label="E&O Policy Number"
                  name="eopolicy_num"
                  disabled={!eopolicy?.carrier_name}
                  value={eopolicy?.eopolicy_num}
                  onChange={(event) =>
                    onChange({
                      target: {
                        name: 'eopolicy_num',
                        value: event.target.value,
                      },
                    })
                  }
                  required
                />
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper',
                  !eopolicy?.per_coverage_amt ? 'active-input' : '',
                  !eopolicy?.id && !eopolicy?.eopolicy_num
                    ? 'disabled-input'
                    : '',
                  eopolicy?.id && !eopolicy?.per_coverage_amt
                    ? 'invalid-input'
                    : '',
                ].join(' ')}
              >
                <MDBInput
                  label="Coverage Amount (each occurrence)"
                  name="per_coverage_amt"
                  disabled={!eopolicy?.carrier_name}
                  value={eopolicy?.per_coverage_amt}
                  onChange={(event) =>
                    onChange({
                      target: {
                        name: 'per_coverage_amt',
                        value: event.target.value,
                      },
                    })
                  }
                  onKeyPress={currencyKeypress}
                  required
                />
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper',
                  !eopolicy?.agg_coverage_amt ? 'active-input' : '',
                  !eopolicy?.id && !eopolicy?.per_coverage_amt
                    ? 'disabled-input'
                    : '',
                  eopolicy?.id && !eopolicy?.agg_coverage_amt
                    ? 'invalid-input'
                    : '',
                ].join(' ')}
              >
                <MDBInput
                  label="Coverage Amount (Aggregate)"
                  name="agg_coverage_amt"
                  disabled={
                    !eopolicy?.carrier_name || !eopolicy?.per_coverage_amt
                  }
                  value={eopolicy?.agg_coverage_amt}
                  onChange={(event) =>
                    onChange({
                      target: {
                        name: 'agg_coverage_amt',
                        value: event.target.value,
                      },
                    })
                  }
                  onKeyPress={currencyKeypress}
                  required
                />
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper',
                  eopolicy?.eopolicy_num &&
                  eopolicy?.carrier_name &&
                  eopolicy?.per_coverage_amt &&
                  eopolicy?.agg_coverage_amt &&
                  !effective_date
                    ? 'active-input'
                    : '',
                  !eopolicy?.id && !eopolicy?.agg_coverage_amt
                    ? 'disabled-input'
                    : '',
                  eopolicy?.id && (!effective_date || !isDatesValid)
                    ? 'invalid-input'
                    : '',
                ].join(' ')}
              >
                <DatePicker
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  disabled={
                    !eopolicy?.eopolicy_num ||
                    !eopolicy?.carrier_name ||
                    !eopolicy?.per_coverage_amt
                  }
                  value={effective_date}
                  dateFormat="MM/DD/YYYY"
                  onChange={(value) =>
                    onChange({
                      target: { name: 'effective_date', value: value },
                    })
                  }
                  customInput={<EffectiveDate />}
                />
                {!isDatesValid ? (
                  <small className="text--red">
                    <strong>
                      Expiration date must occur after Effective date.
                    </strong>
                  </small>
                ) : (
                  <></>
                )}
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div
                className={[
                  'input-wrapper',
                  !expiration_date ? 'active-input' : '',
                  !eopolicy?.id && !eopolicy?.effective_date
                    ? 'disabled-input'
                    : '',
                  eopolicy?.id && (!expiration_date || !isDatesValid)
                    ? 'invalid-input'
                    : '',
                ].join(' ')}
              >
                <DatePicker
                  showYearDropdown
                  yearDropdownItemNumber={20}
                  scrollableYearDropdown
                  minDate={minDate}
                  disabled={!eopolicy?.effective_date}
                  value={expiration_date}
                  dateFormat="MM/DD/YYYY"
                  onChange={(value) =>
                    onChange({
                      target: { name: 'expiration_date', value: value },
                    })
                  }
                  customInput={<ExpiryDate />}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="12" sm="3">
          <div
            className={[
              'input-wrapper upload-input-wrapper',
              !eopolicy?.eopolicy_pdf ? 'active-input' : '',
              !eopolicy?.id ? 'disabled-input' : '',
              eopolicy?.id && !eopolicy?.eopolicy_pdf ? 'invalid-input' : '',
            ].join(' ')}
          >
            {renderFileOutput()}
            {renderGroupPolicy()}
          </div>
        </MDBCol>
      </>
    )
  }

  return (
    <MDBRow id="AgentEandOManagerComponent">
      {renderForm()}
      <MDBModal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        className="confirm-delete-modal"
      >
        <MDBModalHeader toggle={() => setShowModal(false)}>
          Confirm Delete?
        </MDBModalHeader>
        <MDBModalBody>
          <h5 className="text-center">
            <strong>
              Are you sure you want to delete the {eopolicy?.carrier_name} E&O
              Policy Document?
            </strong>
          </h5>
          <br />
          {eopolicy?.eopolicy_pdf && (
            <MDBBtn
              block
              disabled={isDeleting}
              onClick={() => deleteEopolicyDoc()}
            >
              Yes, Delete E&O Policy Document
            </MDBBtn>
          )}
          <MDBBtn
            block
            disabled={isDeleting}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </MDBBtn>
        </MDBModalBody>
      </MDBModal>
    </MDBRow>
  )
}

export default observer(AgentEandOManager)
