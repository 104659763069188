import React, { Component, Fragment } from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import AuthService from './../../shared/services/Auth.service'
import LeaderProductionWidget from './../Reports/components/GrowthReportWidget/LeaderWidget.component'
import AgentNotes from '../../components/agents/AgentNotes/AgentNotes.component'
import appConstants from './../../constants/appConstants'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBSpinner,
  MDBDropdownItem,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from 'mdbreact'
import moment from 'moment'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import { Subscription, BehaviorSubject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import AgentRowSorter from './components/AgentRowSorter/AgentRowSorter.component'
import PaginationControls from './../../components/content/PaginationControls/PaginationControls.component'
import UserProfileService from './../../shared/services/UserProfile.service'
import AgentManagerRow from './components/AgentManagerRow/AgentManagerRow.component'

// Note: DateService & DataManager classes should be merged.
import DateService from './../../shared/services/Date.service'
import DateManager from './../../shared/utilities/DateManager.class'
import { getAgentName } from '../../shared/utilities/getName.function'

import './ManageAgents.scss'
import UserUserTypesService from '../../shared/services/UserUserTypes.service'

const DEFAULT_PER_PAGE = 100
const countableUsertypeIds = [91, 149, 37, 92, 38, 90, 93, 129, 130]

const sortUsertypes = (usertypes) => {
  return (Array.isArray(usertypes) ? usertypes : []).sort(function (a, b) {
    if (
      a.displayname === 'Pending Agents' ||
      b.displayname === 'Pending Agents'
    ) {
      return 0
    }
    if (a.displayname < b.displayname) {
      return -1
    }
    if (a.displayname > b.displayname) {
      return 1
    }
    return 0
  })
}

const sortAgentsByCol = (agents, col, dir) => {
  if (`${dir}`.toLowerCase() === 'asc')
    agents = agents.sort((a, b) =>
      a.sort[col] > b.sort[col] ? 1 : a.sort[col] < b.sort[col] ? -1 : 0
    )

  if (`${dir}`.toLowerCase() === 'desc')
    agents = agents.sort((a, b) =>
      a.sort[col] > b.sort[col] ? -1 : a.sort[col] < b.sort[col] ? 1 : 0
    )

  return agents
}

class ManageAgentsPage extends Component {
  state = {
    usertype_id: 999,
    loading: true,
    fetching: false,
    allUsersTypesCount: 0,
    allUserTypesList: [],
    managersUserTypesList: [],
    agentsList: [],
    selectedagentsList: [],
    imageUrl: {},
    currentHistory: '',
    currentNotesId: null,
    showHistoryModal: false,
    showNotesModal: false,
    pagination: { page: 0, per_page: DEFAULT_PER_PAGE, total: 0 },
    search: '',
    search_keyword: '',
    searching: false,
    sortCol: null,
    sortDir: null,
    canChangeUsertype: UserProfileService.isA([
      'internal-admin',
      'agency-owner',
      'system-admin',
    ]),
  }

  #_searchSubject = new BehaviorSubject('')

  #_subscriptions$ = new Subscription()

  #showPrivateNotes =
    [35, 36, 219, 222].indexOf(
      parseInt(UserProfileService.getCurrentUserTypeId(true))
    ) > -1

  #closeHistoryModel = () =>
    this.setState({ showHistoryModal: false, currentHistory: null })

  #closeNotesModal = () => this.setState({ showNotesModal: false })

  #setActiveUsertype = async (usertypeId) => {
    if (parseInt(this.state.usertype_id) !== parseInt(usertypeId)) {
      this.setState({ fetching: true, search: '' })
      this.setState({
        ...(await this.#fetchAgentsList(usertypeId)),
        usertype_id: usertypeId,
        fetching: false,
      })
    }
  }

  #setSearchParams = async (search, usertypeId) => {
    if (this.state.search !== search) {
      this.setState({ search })
      this.#_searchSubject.next(`${usertypeId}-${search}`)
    }
  }

  #getUsertype = (id, nameOnly) => {
    id = id ? parseInt(id) : parseInt(this.state.usertype_id)

    let usertype = this.state.managersUserTypesList.filter(
      (UT) => parseInt(UT.id) === parseInt(id)
    )

    return nameOnly
      ? usertype && usertype.displayname
      : usertype && usertype.shift()
  }

  #onSortChange = async (col, dir) => {
    this.setState({
      sortCol: col,
      sortDir: dir,
    })
    if (this.state.sortCol === col && this.state.sortDir === dir) {
      col = null
      dir = null
    }

    this.setState({ fetching: true })
    this.setState({
      ...(await this.#fetchAgentsList(
        this.state.usertype_id,
        this.state.pagination.page,
        this.state.pagination.per_page || DEFAULT_PER_PAGE,
        col,
        dir
      )),
      fetching: false,
    })
  }

  #onUserTypeChange = (payload) => {
    let name = [payload?.agent?.u_fname, payload?.agent?.u_lname]
        .join(' ')
        .trim(),
      usertype = null
    name = name ? name : 'the agent'

    if (payload.status === true) {
      usertype = this.state.allUserTypesList
        .filter((u) => parseInt(u.id) === parseInt(payload?.agent?.usertype_id))
        .shift()
      usertype = usertype ? ` to '${usertype.displayname}'` : ''
      name = name ? name : 'the agent'
      toast.success(
        `Great! Successfully updated ${name}'s usertype${usertype}.`,
        { position: toast.POSITION.TOP_RIGHT }
      )
    } else {
      toast.error(`Error! Failed to update ${name}'s usertype.`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  // Fetch Usertypes w/ Agent Counts for Active Usertype Dropdown Selector.
  #fetchUserTypeCounts = async () => {
    let usertypes = null

    try {
      usertypes = (await AdminApi.getUserTypes()).data.data
    } catch (ex) {
      console.error(
        'ERROR: Failed to fetch usertypes in ManageAgentsPage.#fetchUserTypeCounts().  ',
        ex
      )
    }

    return usertypes && Array.isArray(usertypes) ? usertypes : []
  }

  #fetchUserTypeHistory = async (user_ids) => {
    if (
      !Array.isArray(user_ids) ||
      user_ids.filter((id) => id !== null && !isNaN(id)).length === 0
    )
      return Promise.resolve([])

    return new Promise((resolve, reject) => {
      UserUserTypesService.histories({ search: { user_ids } })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  // Fetch Usertypes to populate the Agent change-usertype selector component.
  #fetchUserTypes = async () => {
    let usertypes = null,
      allUsersTypesCount = null

    try {
      const usertTypesList = (
        await AdminApi.getManagersUserTypesList(
          appConstants.pending_agent_days &&
            !isNaN(appConstants.pending_agent_days)
            ? { pending_days: appConstants.pending_agent_days }
            : {}
        )
      ).data

      usertypes = usertTypesList.data

      allUsersTypesCount = usertTypesList.total?.total_curr_signature
        ? parseInt(usertTypesList.total.total_curr_signature)
        : usertypes.reduce(
            (acc, item) =>
              acc +
              (countableUsertypeIds.indexOf(parseInt(item.id)) > -1
                ? item.count
                : 0),
            0
          )
    } catch (ex) {
      console.error(
        'ERROR: Failed to fetch leaders usertype list in ManageAgentsPage.#fetchUserTypes().  ',
        ex
      )
    }

    if (allUsersTypesCount !== null && !isNaN(allUsersTypesCount))
      this.setState({ allUsersTypesCount })

    return usertypes && Array.isArray(usertypes) ? sortUsertypes(usertypes) : []
  }

  #loadUserTypeData = async () => {
    let stateUpd = {
      allUserTypesList: await this.#fetchUserTypeCounts(),
      managersUserTypesList: await this.#fetchUserTypes(),
    }

    let usertypeId = stateUpd.managersUserTypesList.length
      ? stateUpd.managersUserTypesList[0].id
      : null
    if (usertypeId)
      stateUpd = { ...(await this.#fetchAgentsList(usertypeId)), ...stateUpd }

    this.setState({ ...stateUpd, loading: false, searching: false })
  }

  #loginToAgent = async (agent) => {
    if (this.state.loading) return
    this.setState({ loading: true })

    try {
      if (
        await AuthService.assumeUser(
          agent.id,
          UserProfileService.getUserId(true)
        )
      )
        this.props.history.push('/account-profile')
      else
        toast.error(`Error! Unable to login as selected user.`, {
          position: toast.POSITION.TOP_RIGHT,
        })
    } catch (ex) {
      toast.error(`Error! ${ex}`, { position: toast.POSITION.TOP_RIGHT })
    }
  }

  #onPageChange = async (page, perPage) => {
    this.setState({ fetching: true })
    this.setState({
      ...(await this.#fetchAgentsList(
        this.state.usertype_id,
        page,
        perPage || DEFAULT_PER_PAGE
      )),
      fetching: false,
    })
  }

  #fetchAgentsList = async (
    usertypeId,
    page = 0,
    perPage,
    orderby,
    ordertype
  ) => {
    usertypeId = parseInt(usertypeId)
    const data = {
      pending_days: appConstants.pending_agent_days,
      usertype_id: usertypeId,
      page,
      per_page: perPage || DEFAULT_PER_PAGE,
      search: this.state.search,
      orderby: orderby ? orderby : this.state.sortCol,
      ordertype: ordertype ? ordertype : this.state.sortDir,
    }

    return new Promise((resolve, reject) => {
      AdminApi.getManagerAgentsListAccUserType(data)
        .then(async (result) => {
          let agentsList = [],
            pagination = { total: 0, per_page: DEFAULT_PER_PAGE, page: 0 },
            imageUrl = {},
            usertypesHistory = []
          //if (result && result.data && result.data.data && (result.data.pagination.search === this.state.search)) {
          if (result && result.data && result.data.data) {
            agentsList = result.data.data
            pagination = result.data.pagination
            imageUrl = result.data.imageUrl
            usertypesHistory = await this.#fetchUserTypeHistory(
              agentsList.map((a) => a.id)
            )
            this.setState({ fetching: false })
          }

          // Modify agent list values for proper display & sorting.
          agentsList = agentsList.map((agent) => {
            let assignDate = null,
              enrollDate = null

            if (agent?.u_enrolled)
              enrollDate = moment(
                DateManager.dateOnlyUTCToLocal(agent.u_enrolled)
              )

            if (agent?.latest_usertype_assignment) {
              assignDate = moment(
                DateManager.dateOnlyUTCToLocal(
                  agent?.latest_usertype_assignment
                ),
                'LLL'
              )
            }
            if (!assignDate && enrollDate) assignDate = enrollDate

            agent.display = {
              kpis: agent.kpi_goals_complete,
              agent: `${agent.u_fname} ${agent.u_lname}`,
              upline: getAgentName(agent.u_upline),
              enrollment: agent?.u_enrolled
                ? DateService.format(
                    DateService.dateOnly(agent.u_enrolled),
                    'MM/DD/YYYY'
                  )
                : 'N/A',
              contracts: [
                agent?.contracts_requested || 0,
                agent?.contracts_received || 0,
                agent?.contracts_sent || 0,
                agent?.contracts_completed || 0,
              ].join('/'),
              training: [
                (agent?.trainings_attended || 0) >
                (agent?.trainings_required || 8)
                  ? agent?.trainings_required || 8
                  : agent?.trainings_attended || 0,
                agent?.trainings_required || 8,
              ].join('/'),
              assignment: assignDate ? assignDate.format('MM/DD/YYYY') : 'N/A',
            }

            agent.sort = {
              id: parseInt(agent.id),
              kpis: agent.kpi_goals_complete,
              agent: `${agent.u_fname} ${agent.u_lname}`,
              upline: `${agent.u_upline}`.trim(),
              enrollment: agent?.u_enrolled
                ? moment(agent.u_enrolled).toDate().getTime()
                : 0,
              contracts: parseInt(
                (agent?.contracts_received || 0).toString().padStart(2, '0') +
                  '' +
                  (agent?.contracts_sent || 0).toString().padStart(2, '0') +
                  '' +
                  (agent?.contracts_completed || 0).toString().padStart(2, '0')
              ),
              training: [
                (agent?.trainings_attended || 0) >
                (agent?.trainings_required || 8)
                  ? agent?.trainings_required || 8
                  : agent?.trainings_attended || 0,
                agent?.trainings_required || 8,
              ].join('/'),
              assignment: assignDate ? assignDate.toDate().getTime() : 0,
              usertype: `${agent.displayname}`.trim().toLowerCase(),
            }
            return agent
          })

          resolve({ agentsList, pagination, imageUrl })
        })
        .catch((err) => {
          toast.error(
            `Error! Failed to fetch agents from the server.  Please contact support.`,
            { position: toast.POSITION.TOP_RIGHT }
          )
          console.error('ERROR: ' + err)
          resolve({ agentsList: [], pagination: {}, imageUrl: {} })
        })
    })
  }

  #viewAgentNotes = (agent) =>
    this.setState({ currentNotesId: agent?.id, showNotesModal: agent?.id > 0 })

  #viewAgentProfile = (agent) =>
    this.props.history.push(`/${agent.id}/account-profile`)

  #viewAgentHistory = async (agent) => {
    const history = agent.usertype_history
      ? agent.usertype_history
      : "The agent's usertype has not changed since enrollment."

    if (!!history !== !!this.state.showHistoryModal)
      this.setState({ currentHistory: history, showHistoryModal: !!history })
  }

  #setCanChangeUsertype = async () => {
    if (!this.state.canChangeUsertype) {
      let canChangeUsertype = false
      try {
        canChangeUsertype = await UserProfileService.canUser(
          'global.users.update-usertype'
        )
      } catch (ex) {}

      if (canChangeUsertype) this.setState({ canChangeUsertype: true })
    }
  }

  #handleSearch = async (val) => {
    const [usertypeId, search] = val.split('-')

    if (!isNaN(usertypeId) && search) {
      let pagination = { page: 0, per_page: DEFAULT_PER_PAGE, total: 0 }

      this.setState(
        { fetching: true, searching: true, search, pagination },
        async () => {
          this.setState({
            ...(await this.#fetchAgentsList(usertypeId)),
            fetching: false,
            searching: false,
          })
        }
      )
    }
  }

  componentDidMount() {
    this.#loadUserTypeData()
    this.#setCanChangeUsertype()

    this.#_subscriptions$.add(
      this.#_searchSubject
        .pipe(debounceTime(250), distinctUntilChanged())
        .subscribe((term) => this.#handleSearch(term))
    )
  }

  render() {
    toast.configure()

    const {
        loading,
        searching,
        showHistoryModal,
        currentHistory,
        showNotesModal,
        fetching,
      } = this.state,
      { usertype_id, managersUserTypesList } = this.state

    const usertype = this.#getUsertype(usertype_id),
      usertypeName = usertype && usertype.displayname,
      usertypeCount = usertype && usertype.count,
      pagination = this.state.pagination

    return (
      <Fragment>
        <DashboardLayout>
          <main id="ManageAgentsPage" className="mainSection">
            {loading ? (
              <h2>Loading...</h2>
            ) : (
              <>
                {/* <DownlineProduction /> */}
                <LeaderProductionWidget />
                <MDBContainer fluid className="mt-5">
                  <MDBRow>
                    <MDBCol size="12" md="6" lg="6">
                      <h2>Manage My Agents</h2>
                      {usertypeName === 'Pending Agents' ? (
                        <small>
                          Currently viewing <strong>{usertypeName}</strong>{' '}
                          usertype
                          {appConstants.pending_agent_days &&
                          !isNaN(appConstants.pending_agent_days) ? (
                            <>
                              &nbsp;enrolled in the{' '}
                              <strong>
                                last {appConstants.pending_agent_days} days
                              </strong>
                              .
                            </>
                          ) : (
                            '.'
                          )}
                        </small>
                      ) : (
                        <small>Currently Viewing {usertypeName} Usertype</small>
                      )}
                    </MDBCol>
                    <MDBCol size="12" md="6" lg="6">
                      <MDBRow>
                        <MDBCol size="12" md="6" lg="6">
                          <MDBInput
                            type="text"
                            label="Search"
                            id="search"
                            value={this.state.search}
                            getValue={(value) => {
                              this.#setSearchParams(value, usertype_id)
                            }}
                          />
                        </MDBCol>
                        <MDBCol size="12" md="6" lg="6">
                          <MDBDropdown className="usertype-dropdown">
                            <MDBDropdownToggle
                              caret
                              color="indigo"
                              className="usertype-toggle"
                            >
                              <span>{usertypeName}</span>
                              <span>
                                [{usertypeCount} /{' '}
                                {this.state.allUsersTypesCount}]
                              </span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu basic>
                              {managersUserTypesList &&
                                managersUserTypesList.map((usertype, i) => {
                                  return (
                                    <MDBDropdownItem
                                      key={'ut-' + i}
                                      onClick={() =>
                                        this.#setActiveUsertype(usertype.id)
                                      }
                                    >
                                      <span>{usertype.displayname}</span>
                                      <span>[{usertype.count}]</span>
                                    </MDBDropdownItem>
                                  )
                                })}
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol
                      size="12"
                      className="content-wrapper"
                      style={{ paddingTop: '1px', paddingBottom: 0 }}
                    >
                      {fetching || searching ? (
                        <div className="loadingOverlay not-fixed">
                          <h4 className={'loader-text p4'}>
                            <MDBSpinner />
                          </h4>
                        </div>
                      ) : (
                        <>
                          <AgentRowSorter
                            sort={(col, dir) => this.#onSortChange(col, dir)}
                            col={this.state.sortCol}
                            dir={this.state.sortDir}
                          />
                          {this.state.agentsList.map((agent) => {
                            return (
                              <AgentManagerRow
                                key={'agent-row-' + agent.id}
                                agent={agent}
                                imageUrl={this.state.imageUrl}
                                viewNotes={this.#viewAgentNotes}
                                viewHistory={this.#viewAgentHistory}
                                viewProfile={this.#viewAgentProfile}
                                usertypes={this.state.allUserTypesList}
                                onChange={this.#onUserTypeChange}
                                loginTo={this.#loginToAgent}
                                canChangeUsertype={this.state.canChangeUsertype}
                              />
                            )
                          })}
                          {!this.state.agentsList ||
                          this.state.agentsList.length === 0 ? (
                            <div
                              className="content-wrapper text-center"
                              style={{ borderColor: 'transparent' }}
                            >
                              No <strong>{usertypeName}</strong> To Display
                            </div>
                          ) : (
                            <></>
                          )}
                          <div style={{ padding: '0 10px' }}>
                            {pagination && parseInt(pagination.total) ? (
                              <div className="pagination-wrapper">
                                <PaginationControls
                                  pagination={pagination}
                                  onPageSelect={(pagination) =>
                                    this.#onPageChange(
                                      pagination.page,
                                      pagination.per_page
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          {/* <CSVLink className="btn btn-pink" data={this.state.csvData}>Download CSV</CSVLink> */}
                        </>
                      )}
                    </MDBCol>
                  </MDBRow>
                  <MDBModal
                    isOpen={showNotesModal}
                    toggle={this.#closeNotesModal}
                  >
                    <MDBModalHeader toggle={this.#closeNotesModal}>
                      Agent Notes
                    </MDBModalHeader>
                    <MDBModalBody>
                      <AgentNotes></AgentNotes>
                    </MDBModalBody>
                  </MDBModal>
                  <MDBModal
                    isOpen={showHistoryModal}
                    toggle={this.#closeHistoryModel}
                  >
                    <MDBModalHeader toggle={this.#closeHistoryModel}>
                      Usertype History
                    </MDBModalHeader>
                    <MDBModalBody>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentHistory
                            ? currentHistory.replace(
                                /<br \/>/g,
                                '<br /> <br />'
                              )
                            : 'N/A',
                        }}
                      />
                    </MDBModalBody>
                  </MDBModal>
                </MDBContainer>
              </>
            )}
          </main>
        </DashboardLayout>
      </Fragment>
    )
  }
}

export default ManageAgentsPage
