import React from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import EnrollmentStore from './../../shared/Enrollment.store'
import EnrollmentStageIndicator from './../EnrollmentStageIndicator/EnrollmentStageIndicator.component'
import { MDBContainer, MDBRow } from 'mdbreact'
import { getHeadshot } from './../../../../shared/utilities/getHeadshot.function'

import './EnrollmentUpline.scss'

const EnrollmentUpline = ({ upline }) => {
  const isTeamRecruiter = () =>
    parseInt(EnrollmentStore.upline?.is_team_recruiter) === 1 ||
    parseInt(EnrollmentStore.upline?.is_admin_recruiter) === 1

  const teamLogo = () =>
    EnrollmentStore.upline?.team_logo ? (
      <img
        src={`https://firebasestorage.googleapis.com/v0/b/usabg-backoffice-beta.appspot.com/o/${encodeURIComponent(
          'divisions/' + EnrollmentStore.upline?.team_logo
        )}?alt=media`}
        alt=""
      />
    ) : (
      ''
    )

  const headshot = () =>
    EnrollmentStore.upline ? (
      <img src={getHeadshot(toJS(EnrollmentStore.upline))} alt="" />
    ) : (
      ''
    )

  const name = () =>
    [
      EnrollmentStore.upline?.u_nickname
        ? EnrollmentStore.upline?.u_nickname
        : EnrollmentStore.upline?.u_fname,
      EnrollmentStore.upline?.u_lname,
    ].join(' ')

  const title = () => EnrollmentStore.upline?.u_marketing_title

  return (
    <MDBContainer className="EnrollmentUpline">
      <MDBRow>
        <div className="stage-upline-wrapper">
          <div className="upline-photo">
            <div className="img-wrapper">
              {isTeamRecruiter() ? teamLogo() : headshot()}
            </div>
            <div className="upline-name">{name()}</div>
            <div className="upline-title">{title()}</div>
          </div>
        </div>
        <div className="stage-indicator-wrapper">
          <EnrollmentStageIndicator />
        </div>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(EnrollmentUpline)
