import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  UIInput,
  UIInputCheckbox,
} from '../../../../components/forms/form-fields'
import SalesTrackerStore from './../../store'

import './PolicyCommission.component.scss'
import { MDBCol } from 'mdbreact'

const PolicyCommission = ({ Policy, idx }) => {
  return (
    <>
      <MDBCol size="12" md="6" xl="4" className="av-input-wrapper">
        <UIInput
          label="Commission Paid"
          name="comm_paid"
          type="number"
          onChange={(evt) => {
            Policy.set('comm_paid', evt.target.value)
          }}
          value={Policy.get('comm_paid')}
          rules={{ max: 25, min: 1 }}
          showValidity={SalesTrackerStore.showErrors}
          onValidityChange={(validity) =>
            SalesTrackerStore.setValidity(idx, 'comm_paid', validity.isValid)
          }
        />
      </MDBCol>
      <MDBCol
        size="12"
        md="6"
        xl="4"
        className="av-input-wrapper comm-is-paid-check"
      >
        <UIInputCheckbox
          label="Commission Charged Back"
          id={`comm_is_chrg_bk_${idx}`}
          name={`comm_is_chrg_bk_${idx}`}
          checked={Policy.get('comm_is_chrg_bk')}
          onChange={(evt) => {
            Policy.set('comm_is_chrg_bk', !Policy.get('comm_is_chrg_bk'))
          }}
        />
      </MDBCol>
    </>
  )
}

export default observer(PolicyCommission)
