import { makeAutoObservable, toJS } from 'mobx'
import {
  LeaderGoalCardFactory,
  UserFactory,
  BATeamFactory,
} from './../../../shared/factories'
import AgencyContentStore from './../../../shared/stores/agencyContent.store'
import moment from 'moment'

const EMPTY_GOALS = {
  current_total_points: 0,
  current_total_enrolled: 0,
  current_total_completed: 0,
  current_total_converted: 0,
  current_total_sig_agents: 0,
  previous_total_points: 0,
  previous_monthly_goal_points: 0,
  previous_yearly_goal_points: 0,
  previous_total_enrolled: 0,
  previous_monthly_goal_enrolled: 0,
  previous_yearly_goal_enrolled: 0,
  previous_total_completed: 0,
  previous_monthly_goal_completed: 0,
  previous_yearly_goal_completed: 0,
  previous_total_converted: 0,
  previous_monthly_goal_converted: 0,
  previous_yearly_goal_converted: 0,
  previous_total_sig_agents: 0,
  previous_monthly_goal_sig_agents: 0,
  previous_yearly_goal_sig_agents: 0,
}

function areSlotsCompleted(compYear, slotsPurchased) {
  let goalYear = parseInt(compYear) + 1
  slotsPurchased =
    slotsPurchased && typeof slotsPurchased === 'object'
      ? toJS(slotsPurchased)
      : slotsPurchased
  slotsPurchased =
    slotsPurchased && typeof slotsPurchased === 'object' ? slotsPurchased : {}

  if (slotsPurchased.hasOwnProperty(compYear)) {
    // is current year valid.
    let compYearValid =
      slotsPurchased[compYear] &&
      !isNaN(slotsPurchased[compYear]?.personal_previous_slots) &&
      slotsPurchased[compYear]?.personal_previous_slots > 0
    if (!compYearValid) return false

    // is goal year valid.
    if (slotsPurchased.hasOwnProperty(goalYear)) {
      let goalYearlyValid =
        slotsPurchased[goalYear] &&
        !isNaN(slotsPurchased[goalYear]?.personal_yearly_goal_slots) &&
        slotsPurchased[goalYear]?.personal_yearly_goal_slots > 0
      return !!goalYearlyValid
    }
  }

  return false
}

async function fetchAgencyContent() {
  let agencyContent = {},
    agencyContentBySlug = await AgencyContentStore.fetchByContentSlugs({
      contentSlugs: [
        'leader-goals-career-points-notes',
        'leader-goals-district-points-notes',
        'leader-goals-region-points-notes',
        'leader-goals-division-points-notes',
        'leader-goals-career-enrolled-notes',
        'leader-goals-district-enrolled-notes',
        'leader-goals-region-enrolled-notes',
        'leader-goals-division-enrolled-notes',
        'leader-goals-career-completed-notes',
        'leader-goals-district-completed-notes',
        'leader-goals-region-completed-notes',
        'leader-goals-division-completed-notes',
        'leader-goals-career-converted-notes',
        'leader-goals-district-converted-notes',
        'leader-goals-region-converted-notes',
        'leader-goals-division-converted-notes',
        'leader-goals-career-slot-investment-notes',
        'leader-goals-district-slot-investment-notes',
        'leader-goals-region-slot-investment-notes',
        'leader-goals-division-slot-investment-notes',
        'leader-goals-career-usabg-investment-notes',
        'leader-goals-district-usabg-investment-notes',
        'leader-goals-region-usabg-investment-notes',
        'leader-goals-division-usabg-investment-notes',
        'leader-goals-career-sig-agents-notes',
        'leader-goals-district-sig-agents-notes',
        'leader-goals-region-sig-agents-notes',
        'leader-goals-division-sig-agents-notes',
        'leader-goals-career-points-notes',
        'leader-goals-district-points-notes',
        'leader-goals-region-points-notes',
        'leader-goals-division-points-notes',
      ],
    })

  if (agencyContentBySlug) {
    ;['career', 'district', 'region', 'division'].forEach((sourceType) => {
      Object.keys(agencyContentBySlug)
        .filter((contentSlug) => contentSlug.includes(sourceType))
        .forEach((contentSlug) => {
          if (!agencyContent.hasOwnProperty(sourceType))
            agencyContent[sourceType] = {}
          agencyContent[sourceType][contentSlug.replace(`${sourceType}-`, '')] =
            agencyContentBySlug[contentSlug]
        })
    })
  }

  return agencyContent
}

async function createMeta(MetaSrvc, metaKey) {
  let Meta = await MetaSrvc.create({ meta_key: metaKey })
  await Meta.save()
  return Meta
}

async function getRequiredUserMetas(User) {
  let [SlotsPurchasedMeta, GrowthGoalMeta] = await Promise.all([
    Promise.resolve(
      User ? User.meta(true).key('marketing---slots-purchased') : []
    ),
    Promise.resolve(
      User ? User.meta(true).key('profile---leader-growth-goal') : []
    ),
  ])

  if (
    !(SlotsPurchasedMeta = Array.isArray(SlotsPurchasedMeta)
      ? SlotsPurchasedMeta.shift()
      : SlotsPurchasedMeta)
  )
    SlotsPurchasedMeta = await createMeta(
      User.meta(true),
      'marketing---slots-purchased'
    )

  if (
    !(GrowthGoalMeta = Array.isArray(GrowthGoalMeta)
      ? GrowthGoalMeta.shift()
      : GrowthGoalMeta)
  )
    GrowthGoalMeta = await createMeta(
      User.meta(true),
      'profile---leader-growth-goal'
    )

  return {
    SlotsPurchasedMeta,
    GrowthGoalMeta,
  }
}

const determineSourceType = (User) => {
  let sourceType = null
  if (User.isA(['divisional-leader', 'agency-owner', 'division-admin']))
    sourceType = 'division'
  else if (User.isA(['regional-leader'])) sourceType = 'region'
  else if (User.isA(['district-leader'])) sourceType = 'district'
  else if (User.isA(['system-admin'])) sourceType = 'division'
  else sourceType = 'UNKNOWN'
  return sourceType
}

const fetchUsertype = async (User) => {
  const BATeam = (
    (
      await BATeamFactory.search({
        search: { owner_id: User.id() },
      })
    )?.models || []
  ).shift()

  return {
    isBaLeader: !!BATeam,
    isDivisionLeader: User.isA('divisional-leader'),
    isRegionLeader: User.isA('regional-leader'),
    isDistrictLeader: User.isA('district-leader'),
  }
}

const fetchTeam = async (goalDate, User) => {
  goalDate = goalDate ? moment(goalDate, 'YYYY-MM-DD') : moment()

  const source_type = determineSourceType(User),
    source_id = User.id(),
    curr_end_at = goalDate.format('YYYY-MM-DD'),
    prev_end_at = goalDate
      .subtract(1, 'years')
      .endOf('year')
      .format('YYYY-MM-DD'),
    ReportModels = await LeaderGoalCardFactory.search({
      search: { source_type, source_id, curr_end_at, prev_end_at },
      pagination: false,
    })

  return ReportModels.shift()
}

const fetchPersonal = async (goalDate, User) => {
  goalDate = goalDate ? moment(goalDate, 'YYYY-MM-DD') : moment()

  const source_type = 'recruiter',
    source_id = User.id(),
    curr_end_at = goalDate.format('YYYY-MM-DD'),
    prev_end_at = goalDate
      .subtract(1, 'years')
      .endOf('year')
      .format('YYYY-MM-DD'),
    ReportModels = await LeaderGoalCardFactory.search({
      search: { source_type, source_id, curr_end_at, prev_end_at },
      pagination: false,
    })

  return ReportModels.shift()
}

class LeaderGoalCardsStore {
  constructor() {
    makeAutoObservable(this)
  }

  hasInit = false
  sourceType = ''
  teamGoals = {}
  personalGoals = {}
  slotsPurchased = {}
  growthGoal = {}
  agencyContent = {}
  usertype = {
    isBaLeader: false,
    isDivisionLeader: false,
    isRegionLeader: false,
    isDistrictLeader: false,
  }

  goalDate = null
  isLoading = false
  isSlotsLoading = false
  isAutoForecast = false

  SlotsPurchasedMeta = null
  GrowthGoalMeta = null

  setViewData = ({ Team, Personal, Slots, growthGoal }) => {
    const teamGoals = Team ? Team.all() : EMPTY_GOALS,
      personalGoals = Personal ? Personal.all() : EMPTY_GOALS,
      { slotsPurchased } = Slots || {}

    this.teamGoals = teamGoals
    this.personalGoals = personalGoals
    this.slotsPurchased = slotsPurchased
    this.growthGoal = growthGoal
  }

  getGoalYear = (goalDate) => {
    if (
      (goalDate = goalDate
        ? moment(`${goalDate}`, 'YYYY-MM-DD')
        : this.goalDate
        ? moment(`${this.goalDate}`, 'YYYY-MM-DD')
        : null)
    )
      return goalDate.format('YYYY')
    return
  }

  setGoalDate = async (goalDate) => {
    if (this.goalDate !== goalDate) {
      this.goalDate =
        goalDate && typeof goalDate === 'object'
          ? moment(goalDate).format('YYYY-MM-DD')
          : goalDate
      this.updateReport()
    }
  }

  areSlotsCompleted = (compYear, slotsPurchased) => {
    return areSlotsCompleted(compYear, slotsPurchased)
  }

  setSlotsPurchased = (changes, UserMeta) => {
    Object.keys(changes).forEach(
      (key) =>
        (changes[key] = !isNaN(changes[key])
          ? parseFloat(changes[key])
          : changes[key])
    )

    if (UserMeta && UserMeta?.id() === this.SlotsPurchasedMeta?.id()) {
      this.isSlotsLoading = true

      let year = changes.year,
        metaValue = this.getMetaValue(this.SlotsPurchasedMeta)

      delete changes.year

      if (!metaValue.hasOwnProperty(year)) metaValue[year] = {}
      metaValue[year] = { ...metaValue[year], ...changes }

      this.SlotsPurchasedMeta.set('meta_value', JSON.stringify(metaValue))
      this.slotsPurchased = this.getMetaValue(this.SlotsPurchasedMeta)
      this.isSlotsLoading = false
    }
  }

  setUser = (User) => {
    this.sourceType = determineSourceType((this.User = User))
  }

  saveMeta = async (Meta, investData) => {
    this.isSlotsLoading = true
    if (Meta && Meta.id() === this.SlotsPurchasedMeta?.id())
      await this.SlotsPurchasedMeta.save()
    this.isSlotsLoading = false
  }

  getAgencyContent = (key, prop) => {
    try {
      if (prop) {
        let ac =
          this.sourceType && toJS(this.agencyContent[this.sourceType][key])
        return ac && ac?.[prop]
      }
      return this.sourceType && toJS(this.agencyContent[this.sourceType][key])
    } catch (ex) {
      return ''
    }
  }

  getMetaValue = (Meta) => {
    try {
      Meta =
        Meta && Meta.get('meta_value') ? `${Meta.get('meta_value')}`.trim() : ''
      return Meta ? (typeof Meta === 'string' ? JSON.parse(Meta) : Meta) : {}
    } catch (ex) {
      return {}
    }
  }

  getGrowthGoal = (goalYear) => {
    let goal = this.growthGoal[goalYear || this.getGoalYear()]
    if (goal && !isNaN(goal)) return goal
  }

  setGrowthGoal = async (goal) => {
    let goalYear = goal && Object.keys(goal).shift()
    goalYear =
      goalYear && !isNaN(goalYear) ? parseInt(goalYear) : this.getGoalYear()

    if (
      this.GrowthGoalMeta &&
      goalYear &&
      parseFloat(goal[goalYear]) !== parseFloat(this.growthGoal[goalYear])
    ) {
      this.GrowthGoalMeta.set(
        'meta_value',
        JSON.stringify(
          (this.growthGoal = {
            ...toJS(this.growthGoal),
            ...(goal ? goal : {}),
          })
        )
      )

      if (await this.GrowthGoalMeta.save()) this.updateReport()
    }

    return
  }

  async updParams(params) {
    this.isLoading = true
    if (!this.hasInit)
      fetchAgencyContent().then((content) => (this.agencyContent = content))

    const { goalDate, reportDate, userId } = params || {}
    this.goalDate = goalDate || reportDate
    this.setViewData(await this.fetch(params || {}))
    this.isLoading = false
    this.hasInit = true
  }

  async fetch(opts) {
    if (!this.isLoading) this.isLoading = true
    opts = opts && typeof opts === 'object' ? opts : {}

    let userId = opts?.userId,
      User = (userId && (await UserFactory.findById(userId))) || this.User

    if (!this.User || this.User.id() !== User.id()) this.setUser(User)

    this.isSlotsLoading = true
    const [usertype, TeamGoal, PersonalGoal, InvestMeta] = await Promise.all([
        fetchUsertype(this.User),
        fetchTeam(this.goalDate, this.User),
        fetchPersonal(this.goalDate, this.User),
        getRequiredUserMetas(this.User),
      ]),
      { SlotsPurchasedMeta, GrowthGoalMeta } = InvestMeta || {}

    let slotsPurchased = this.getMetaValue(SlotsPurchasedMeta),
      growthGoal = this.getMetaValue(GrowthGoalMeta)
    this.isSlotsLoading = false

    this.usertype = usertype
    this.SlotsPurchasedMeta = SlotsPurchasedMeta
    this.GrowthGoalMeta = GrowthGoalMeta

    this.isLoading = false
    return {
      Team: TeamGoal || null,
      Personal: PersonalGoal || null,
      Slots: { slotsPurchased },
      growthGoal,
    }
  }

  updateReport = async () => {
    this.setViewData(await this.fetch())
  }

  canEnableAutoForecast = () => {
    if (this.goalDate) {
      const goalYear = moment(this.goalDate, 'YYYY-MM-DD').format('YYYY'),
        compYear = goalYear && !isNaN(goalYear) ? goalYear - 1 : false

      if (compYear && new Date().getFullYear() <= compYear) return true
    }

    return false
  }

  enableAutoForecast = () => {
    this.isAutoForecast = true
  }

  disableAutoForecast = () => {
    this.isAutoForecast = false
  }

  shouldAutoForecast = () => {
    return this.canEnableAutoForecast() && this.isAutoForecast === true
  }

  applyAutoForecast = (value) => {
    if (!this.shouldAutoForecast()) return value

    const goalDate = moment(this.goalDate, 'YYYY-MM-DD'),
      goalYear = goalDate.format('YYYY'),
      compYear = goalYear && !isNaN(goalYear) ? goalYear - 1 : false,
      dayOfYear = moment(
        `${compYear}-${goalDate.format('MM-DD')}`,
        'YYYY-MM-DD'
      ).dayOfYear(),
      perDay = value / dayOfYear

    return perDay * 365
  }
}

export default new LeaderGoalCardsStore()
