import React from 'react'
import Modal from './Modal.component'
import { create } from 'react-modal-promise'

const ModalPromise = create(Modal)

class UpdateVoidedCheckModal {
  open(props) {
    props = props && typeof props === 'object' ? props : {}
    const isOpen = !!(props?.is_open || props?.isOpen)

    return new Promise((resolve, reject) => {
      ModalPromise({ ...props, isOpen }).then(resolve, reject)
    })
  }
}

export default new UpdateVoidedCheckModal()
