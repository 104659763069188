import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import AdminApi from './../../../../api/admin-api/admin-api'
import { WebsiteInfoStore } from './../../stores'
import { MDBBtn, MDBRow, MDBCol, MDBCollapse, MDBAlert } from 'mdbreact'
import TextEditor from '../../../../components/shared/TextEditor/TextEditor.component'
import ContentHtml from './../../../../components/content/ContentHtml/ContentHtml.component'
import { phoneToStorage } from './../../../../shared/formatters/telephone.formatter'

import './WebsiteInfoForm.component.scss'

const saveTimer = {}

const WebsiteInfoForm = ({ s_agent_picture }) => {
  const [formError, setFormError] = useState(null),
    [formSuccess, setFormSuccess] = useState(null)

  const onSelectChange = (event) =>
    (WebsiteInfoStore.siteDetails[event.target.name] = event.target.value)

  const validate = () => {
    const { siteDetails } = WebsiteInfoStore,
      regHttpLink = /^(http(s)?:\/\/).*/i

    if (!siteDetails.theme_name) {
      setFormError('Please select a theme')
      return false
    }

    if (
      siteDetails.s_web_confrence_link &&
      siteDetails.s_web_confrence_link.trim() &&
      !regHttpLink.test(siteDetails.s_web_confrence_link)
    ) {
      const { s_web_confrence_link } = siteDetails
      WebsiteInfoStore.siteDetails.s_web_confrence_link = `http://${s_web_confrence_link}`
    }

    if (
      siteDetails.rx_card_link &&
      siteDetails.rx_card_link.trim() &&
      !regHttpLink.test(siteDetails.rx_card_link)
    ) {
      const { rx_card_link } = siteDetails
      WebsiteInfoStore.siteDetails.rx_card_link = `http://${rx_card_link}`
    }

    // if (!siteDetails?.s_about || !siteDetails.s_about.trim()) {
    //   setFormError('About Info Required')
    //   return false
    // }

    setFormError(null)
    return true
  }

  const updateInfo = async () => {
    if (!(await validate())) return false

    if (WebsiteInfoStore.isLoading || WebsiteInfoStore.isSaving) return

    const data = {
      s_agent_picture: WebsiteInfoStore.siteDetails?.s_agent_picture ? 1 : 0,
      s_fulladdress: WebsiteInfoStore.siteDetails?.s_fulladdress ? 1 : 0,
      s_state_license_show: WebsiteInfoStore.siteDetails?.s_state_license_show
        ? 1
        : 0,
      theme_name: WebsiteInfoStore.siteDetails?.theme_name,
      s_email_override: WebsiteInfoStore.siteDetails?.s_email_override
        ? WebsiteInfoStore.siteDetails.s_email_override
        : '',
      s_phone_override: WebsiteInfoStore.siteDetails?.s_phone_override
        ? phoneToStorage(WebsiteInfoStore.siteDetails.s_phone_override)
        : '',
      s_web_confrence_link: WebsiteInfoStore.siteDetails?.s_web_confrence_link
        ? WebsiteInfoStore.siteDetails.s_web_confrence_link
        : '',
      rx_card_link: WebsiteInfoStore.siteDetails?.rx_card_link
        ? WebsiteInfoStore.siteDetails.rx_card_link
        : '',
      s_about: WebsiteInfoStore.siteDetails?.s_about,
      id: WebsiteInfoStore.siteDetails?.site_id,
    }

    let form_data = new FormData()
    for (let key in data) form_data.append(key, data[key])

    WebsiteInfoStore.isSaving = true

    AdminApi.updateWebsiteInfo(form_data)
      .then((result) => {
        setFormSuccess('Successfully updated website info.')

        if (saveTimer.timer) {
          window.clearTimeout(saveTimer.timer)
          saveTimer.timer = null
        }

        saveTimer.timer = setTimeout(() => setFormSuccess(null), 4000)

        WebsiteInfoStore.isSaving = false
      })
      .catch((error) => {
        setFormError(error)
        WebsiteInfoStore.isSaving = false
      })
  }

  return (
    <form
      id="WebsiteInfoFormComponent"
      noValidate
      className="container-fluid mt-5"
    >
      <MDBRow>
        <MDBCol size="12">
          <h5 className="mb-4 text--blue font--lato fw--500">
            Agent Subsite Configuration
          </h5>
        </MDBCol>
        <MDBCol size="12">
          <MDBRow>
            <MDBCol size="12" lg="4" sm="12">
              <div
                className={`alert check-wrapper ${
                  WebsiteInfoStore.siteDetails?.s_agent_picture
                    ? 'alert-success'
                    : 'alert-info'
                }`}
                style={{ padding: '2px', fontSize: '14px' }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={WebsiteInfoStore.siteDetails?.s_agent_picture}
                  checked={WebsiteInfoStore.siteDetails?.s_agent_picture}
                  onChange={(e) => {
                    WebsiteInfoStore.siteDetails.s_agent_picture =
                      !WebsiteInfoStore.siteDetails?.s_agent_picture
                  }}
                  id="s_agent_picture"
                />
                <label className="form-check-label" htmlFor="s_agent_picture">
                  Include My Agent Photo
                </label>
              </div>
            </MDBCol>
            <MDBCol size="12" lg="4" sm="12">
              <div
                className={`alert check-wrapper ${
                  WebsiteInfoStore.siteDetails?.s_fulladdress
                    ? 'alert-success'
                    : 'alert-info'
                }`}
                style={{ padding: '2px', fontSize: '14px' }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={WebsiteInfoStore.siteDetails?.s_fulladdress}
                  checked={WebsiteInfoStore.siteDetails?.s_fulladdress}
                  onChange={(e) => {
                    WebsiteInfoStore.siteDetails.s_fulladdress =
                      !WebsiteInfoStore.siteDetails?.s_fulladdress
                  }}
                  id="s_fulladdress"
                />
                <label className="form-check-label" htmlFor="s_fulladdress">
                  Include My Full Address
                </label>
              </div>
            </MDBCol>
            <MDBCol size="12" lg="4" sm="12">
              <div
                className={`alert check-wrapper ${
                  WebsiteInfoStore.siteDetails?.s_state_license_show
                    ? 'alert-success'
                    : 'alert-info'
                }`}
                style={{ padding: '2px', fontSize: '14px' }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={WebsiteInfoStore.siteDetails?.s_state_license_show}
                  checked={WebsiteInfoStore.siteDetails?.s_state_license_show}
                  onChange={(e) => {
                    WebsiteInfoStore.siteDetails.s_state_license_show =
                      !WebsiteInfoStore.siteDetails?.s_state_license_show
                  }}
                  id="s_state_license_show"
                />
                <label
                  className="form-check-label"
                  htmlFor="s_state_license_show"
                >
                  Show State License
                </label>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group" size="12" sm="6" lg="4">
          <label>Website Theme</label>
          <select
            className="form-control"
            name="theme_name"
            value={WebsiteInfoStore.siteDetails?.theme_name}
            onChange={onSelectChange}
          >
            <option value="business1">Business 1</option>
            <option value="business2">Business 2</option>
            <option value="business3">Business 3</option>
            <option value="construction1">Construction 1</option>
            <option value="construction2">Construction 2</option>
            <option value="doctors">Doctor</option>
            <option value="farmer">Farmer</option>
            <option value="mechanic">Mechanic</option>
            <option value="nurses">Nursing</option>
            <option value="realEstate">Real Estate</option>
            <option value="seniors">Senior</option>
            <option value="vet">Vet</option>
          </select>
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="4">
          <label>Override Public Email Address</label>
          <input
            type="text"
            className="form-control"
            name="s_email_override"
            id="s_email_override"
            value={WebsiteInfoStore.siteDetails?.s_email_override || ''}
            onChange={onSelectChange}
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="4">
          <label>Override Public Phone Number</label>
          <input
            type="text"
            className="form-control"
            name="s_phone_override"
            id="s_phone_override"
            value={WebsiteInfoStore.siteDetails?.s_phone_override || ''}
            onChange={onSelectChange}
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="6">
          <label>Web Conference Link</label>
          <ContentHtml
            contentSlug="subsite-howto-web-conference-link"
            editor={{ iconStyle: { transform: 'translateY(-20px)' } }}
          />
          <input
            type="text"
            className="form-control"
            name="s_web_confrence_link"
            id="s_web_confrence_link"
            value={WebsiteInfoStore.siteDetails?.s_web_confrence_link || ''}
            onChange={onSelectChange}
          />
        </MDBCol>
        <MDBCol className="form-group" size="12" sm="6" lg="6">
          <label>Rx Card Link</label>
          <ContentHtml
            contentSlug="subsite-howto-rx-card-link"
            editor={{ iconStyle: { transform: 'translateY(-20px)' } }}
          />
          <input
            type="text"
            className="form-control"
            name="rx_card_link"
            id="rx_card_link"
            value={WebsiteInfoStore.siteDetails?.rx_card_link || ''}
            onChange={onSelectChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group" size="12">
          <label className="d-block">About Content</label>
          <TextEditor
            value={WebsiteInfoStore.siteDetails?.s_about}
            onChange={(html) => {
              if (WebsiteInfoStore.siteDetails) {
                WebsiteInfoStore.siteDetails.s_about = html
              }
            }}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="form-group">
          <MDBBtn color="indigo" type="button" onClick={updateInfo}>
            {WebsiteInfoStore.isSaving ? 'Saving...' : 'Save'}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCollapse isOpen={!!formError}>
            <MDBAlert color="danger">{formError}</MDBAlert>
          </MDBCollapse>
          <MDBCollapse isOpen={!!formSuccess}>
            <MDBAlert color="success">{formSuccess}</MDBAlert>
          </MDBCollapse>
        </MDBCol>
      </MDBRow>
    </form>
  )
}

export default observer(WebsiteInfoForm)
