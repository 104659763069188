import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBBtn, MDBIcon, MDBBtnGroup, MDBCollapse } from 'mdbreact'
import {
  UIInputCheckbox,
  UIDropdown,
} from './../../../../components/forms/form-fields'
import LGCStore from './../../store/LeaderGoalCard.store'
import './LeaderGoalsSelector.scss'

const DEFAULT_GOAL = 25,
  GROWTH_GOALS = [40, 35, 30, 25, 20, 15]

const LeaderGoalsSelector = ({ viewMode, ownerName }) => {
  const { setGrowthGoal, getGoalYear, getGrowthGoal } = LGCStore,
    growthGoal = getGrowthGoal(),
    goalYear = getGoalYear(),
    isNoGoal =
      goalYear &&
      goalYear < new Date().getFullYear() &&
      typeof growthGoal === 'undefined',
    isEmptyGoal =
      goalYear &&
      goalYear >= new Date().getFullYear() &&
      typeof growthGoal === 'undefined',
    renderSelector = () => {
      if (!isNoGoal && !isEmptyGoal)
        return (
          <div className="content-wrapper">
            <UIDropdown
              disabled={goalYear && goalYear < new Date().getFullYear()}
              label="Growth Goal"
              name="growth_goal"
              options={GROWTH_GOALS.map((goal) => ({
                text: `${goal}%`,
                value: parseFloat(goal / 100),
                checked:
                  growthGoal &&
                  parseFloat(goal / 100) === parseFloat(growthGoal),
              }))}
              onChange={(evt) =>
                setGrowthGoal({ [goalYear]: evt.target.value })
              }
              value={growthGoal}
            />
          </div>
        )

      if (isEmptyGoal)
        return (
          <div className="content-wrapper empty-goal">
            <UIDropdown
              disabled={goalYear && goalYear < new Date().getFullYear()}
              label="Growth Goal"
              name="growth_goal"
              options={GROWTH_GOALS.map((goal) => ({
                text: `${goal}%`,
                value: parseFloat(goal / 100),
                checked: parseInt(goal) === parseInt(DEFAULT_GOAL),
              }))}
              onChange={(evt) =>
                setGrowthGoal({ [goalYear]: evt.target.value })
              }
              value={growthGoal}
            />
          </div>
        )

      return (
        <div className="content-wrapper no-goal">
          No goal set for {goalYear}
        </div>
      )
    }

  return (
    <div id="LeaderGoalsSelectorComponent">
      <MDBCollapse isOpen={viewMode === 'goals'}>
        <div className="row">
          <div className="goal-owner">
            {ownerName ? `Viewing ${ownerName}'s Goals` : `Viewing Goals`}
          </div>
          {renderSelector()}
        </div>
      </MDBCollapse>
    </div>
  )
}

export default observer(LeaderGoalsSelector)
